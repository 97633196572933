import { useState, useEffect, useRef } from 'react';
import { ScrollView, Button, TabPanel, TextBox, FileUploader } from 'devextreme-react';
import { DataGrid, SearchPanel, HeaderFilter, Search, Column, Pager } from 'devextreme-react/data-grid';
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader';
import { Link } from 'react-router-dom'
import { Item } from 'devextreme-react/tab-panel';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux'
import { RootState } from '../../../reduxStore/reducers'
import { GetNamesEmployees } from '../../../components/Evaluations/ComponentsEvaluation'
import * as Fnctn from '../../../contexts/GenericFunctions';
import * as FireBase from '../../../contexts/FirebaseActions'
import * as nfActions from '../../../reduxStore/NewForms/NF_actions';
import * as XLSX from 'xlsx';
import notify from 'devextreme/ui/notify';
import '../Evaluations.scss'
import ExcelJS from 'exceljs';

function EditEvaluation(props: any) {
    const [Id_Evaluation] = useState<any>(props.select_IdEvaluation);
    const [Titulo, setTitle] = useState<string>(props.Select_Title);
    const [Descripcion, setDescription] = useState<string>(props.Select_Description);
    const [dtEvaluators, setEvaluators] = useState<any[]>(props.Select_Evaluators);
    const [dtEvaluateds, setEvaluateds] = useState<any[]>(props.Select_Evaluateds);
    const [order, setOrder] = useState<number>(props.Select_Order);
    const [id_form] = useState<string | null>(props.Select_FormId);
    const [imgPath, setImgPath] = useState<string>(props.Select_Img_path);
    const [imgName, setImgName] = useState<string>(props.Select_Img_name);
    const [deployee, setDeploye] = useState<boolean>(props.Select_Deploye);
    const [FotoTarea, setFotoTarea] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const gridRefEvaluators = useRef<any>(null);
    const gridRefEvaluateds = useRef<any>(null);
    const [dtEmployeesTemplate, setDtEmployeesTemplate] = useState<[{cvetraEvaluator:string, levelEvaluator: string, cvetraEvaluated:string}]|any[]>([]);

    useEffect(() => {
        halcoloaderActive();
        Getdatos();
        halcoloaderRemove();
    }, []);

    const handleTextChanged = (e: any, Textbox: string = "") => {
        if (Textbox === "Titulo") setTitle(e.value);
        if (Textbox === "Descripcion") setDescription(e.value);
    };

    const Getdatos = async () => {
        try {
            halcoloaderActive();
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();
            const objEvaluation = { "_id": Id_Evaluation }

            let dtresult: any = await fetch(`${api}/rating/evaluations`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(objEvaluation)
            });
            dtresult = await dtresult.json();

            let Evaluators: any[] = [];
            let Evaluateds: any[] = [];
            let employeesList: any[] = [];

            dtresult.data[0].employees_template.forEach((item: any) => {
                Evaluators.push(item.cvetraEvaluator);
                Evaluateds.push(item.cvetraEvaluated);
                employeesList.push({ cvetraEvaluator: item.cvetraEvaluator, levelEvaluator: item.Nivel, cvetraEvaluated: item.cvetraEvaluated });
            });

            let arrayEmployes: any = {
                Evaluadores: Evaluators,
                Evaluados: Evaluateds
            };

            let EmployeesResult: any = await GetNamesEmployees(arrayEmployes, true, Id_Evaluation);

            setDtEmployeesTemplate(employeesList);
            setTitle(dtresult.data[0].name);
            setDescription(dtresult.data[0].description);
            setEvaluators(EmployeesResult[0]);
            setEvaluateds(EmployeesResult[1]);
            setImgPath(dtresult.data[0].img_path);
            setImgName(dtresult.data[0].img_name);
            setOrder(dtresult.data[0].order);
            halcoloaderRemove();
        }
        catch (error) {
            halcoloaderRemove();
            notify(error, "error");
        }
    }

    const getFiles = (dataFile: any) => {
        const file = dataFile.value[0];
        const reader = new FileReader();

        reader.onload = async (e: any) => {
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: 'array' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const [header, ...rows]: any = jsonData;
            let dtExcel = rows.map((row: any) => {
                const obj: any = {};
                header.forEach((key: any, index: any) => {
                    if (!Fnctn.NullUndefinedEmpty(row[index])) obj[key] = row[index];
                    else obj[key] = "-";
                });
                return obj;
            });
            let employeesList: [{ cvetraEvaluator: string, levelEvaluator:string, cvetraEvaluated: string }] | any[] = [];
            dtExcel.forEach((item: any) => {
                if (item.Evaluadores !== '-') {
                    if (item.Nivel !== '-') {
                        if (item.Evaluados !== '-') {
                            employeesList.push({ cvetraEvaluator: item.Evaluadores, levelEvaluator: item.Nivel, cvetraEvaluated: item.Evaluados });
                        }
                        else notify("Falta agregar un evaluado en la lista", "error")
                    }
                    else notify("Todos los evaluadores deben llevar un Nivel", "error")
                }
                else notify("Falta agregar un evaluador en la lista", "error")
            });

            let Evaluators: any[] = [];
            let Evaluateds: any[] = [];

            employeesList.forEach((item: any) => {
                Evaluators.push(item.cvetraEvaluator);
                Evaluateds.push(item.cvetraEvaluated);
            });
            dtExcel = { Evaluadores: Evaluators, Evaluados: Evaluateds }
            let EmployeesResult: any = await GetNamesEmployees(dtExcel, false, "");
            for(let i: number = 0; i < EmployeesResult[0].length; i++){
                EmployeesResult[0][i].levelEvaluator = await employeesList[i].levelEvaluator;
            }
            setDtEmployeesTemplate(employeesList);
            setEvaluators(EmployeesResult[0]);
            setEvaluateds(EmployeesResult[1]);
        };

        reader.readAsArrayBuffer(file);
    }

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setFotoTarea(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String: any = reader.result!.toString();
                setImgPath(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const Guardar = async () => {
        try {
            halcoloaderActive();
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();
            let IconoTarea: any = null;
            let defaultIcon = "https://firebasestorage.googleapis.com/v0/b/halconet-27dd9.appspot.com/o/HalcoHumand%2FICONOS_TAREAS%2Flogo-halconet_sinletras.svg?alt=media&token=cd51f543-4a9b-4685-85f7-50f9976b4146";

            if (!Fnctn.NullUndefinedEmpty(Titulo)) {
                if (!Fnctn.NullUndefinedEmpty(Descripcion)) {
                    if (!Fnctn.NullUndefinedEmpty(dtEvaluators) && dtEvaluators.length > 0) {
                        if (!Fnctn.NullUndefinedEmpty(dtEvaluateds) && dtEvaluateds.length > 0) {
                            let evaluatorsArray: any = [];
                            let evaluatedsArray: any = [];

                            dtEvaluators.forEach((item) => {
                                if (item.name_employee !== "No encontrado") evaluatorsArray.push(item.cvetra);
                                else throw (`El No. Empleado ${item.cvetra} no fue encontrado`);
                            });

                            dtEvaluateds.forEach((item) => {
                                if (item.name_employee !== "No encontrado") evaluatedsArray.push(item.cvetra);
                                else throw (`El No. Empleado ${item.cvetra} no fue encontrado`);
                            });

                            if (!Fnctn.NullUndefinedEmpty(FotoTarea)) {
                                if (!imgName.includes("sinletras")) {
                                    IconoTarea = await FireBase.DeleteFile(imgName, FireBase.enumPath.ICONOS_TAREAS);
                                }
                                IconoTarea = await FireBase.uploadFile(FotoTarea, FireBase.enumPath.ICONOS_TAREAS);
                                await setImgName(IconoTarea.NameFirebase);
                            }
                            else {
                                IconoTarea = {
                                    PathFireBase: !Fnctn.NullUndefinedEmpty(props.Select_Img_path) ? props.Select_Img_path : defaultIcon,
                                    NameFirebase: !Fnctn.NullUndefinedEmpty(props.Select_Img_name) ? props.Select_Img_name : "sinletras"
                                };
                                setImgPath(IconoTarea.PathFireBase);
                            }

                            let Objt = {
                                "name": Titulo
                                , "description": Descripcion
                                , "employees_template": dtEmployeesTemplate
                                , "img_path": IconoTarea.PathFireBase
                                , "img_name": IconoTarea.NameFirebase
                                , "order": order
                                , "form_id": id_form
                            };

                            let dtEvaluations: any = await fetch(`${api}/rating/evaluations/${Id_Evaluation}`, {
                                method: 'PUT',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.token}`
                                },
                                body: JSON.stringify(Objt)
                            });

                            dtEvaluations = await dtEvaluations.json();
                            if (dtEvaluations.valid) {
                                for (let i: number = 0; i < dtEvaluators.length; i++) {

                                    let Objt = {
                                        "evaluation_id": Id_Evaluation,
                                        "evaluator_cvetra": dtEvaluators[i].cvetra,
                                        "evaluator_name": dtEvaluators[i].name_employee,
                                        "evaluated_cvetra": dtEvaluateds[i].cvetra,
                                        "evaluated_name": dtEvaluateds[i].name_employee
                                    }

                                    let assignationResult: any = await fetch(`${api}/rating/assign_evaluation`, {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${data.token}`
                                        },
                                        body: JSON.stringify(Objt)
                                    });
                                    assignationResult = await assignationResult.json();

                                    console.log(assignationResult);
                                    notify("Evaluacion actualizada correctamente", "success");

                                }
                                notify("Evaluacion agregada correctamente", "success");
                            }
                            else throw (dtEvaluations.message);
                            halcoloaderRemove();
                        } else throw ("Debes agregar al menos un evaluado");
                    } else throw ("Debes agregar al menos un evaluador");
                } else throw ("Debes agregar una descripción");
            } else throw ("Debes agregar un título");
        }
        catch (error) {
            halcoloaderRemove();
            notify(error, 'error');
        }
    };

    const panelItemClick = (e: any) => {
        if (e.itemData.title === "Resultados") {
            console.log(e);
        }
    }

    const EditForm = async () => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let dtTask = await fetch(`${api}/training/evaluations/${id_form}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });

        let dtForm = await dtTask.json();
        let questions: any = dtForm.data[0].questionnaire[0].questions;
        dispatch(nfActions.set_form_id(dtForm.data[0]._id));
        dispatch(nfActions.set_form_name(Titulo));
        dispatch(nfActions.set_form_returnview("/Evaluations"));
        dispatch(nfActions.set_form_questions(questions));
        dispatch(nfActions.set_type_form("E"));
        await navigate('/Cuestionario');
        halcoloaderRemove();
    }

    const handleExport = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Hoja1');
        const headers = ['Evaluadores', 'Evaluados'];

        worksheet.addRow(headers);
        dtEvaluators.forEach((item, index) => {
            const item2 = dtEvaluateds[index];
            worksheet.addRow([item.cvetra, item2 ? item2.cvetra : '']);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Template_${Titulo}.xlsx`;
            link.click();
        });
    };

    const DeployeEvaluation = async (status:boolean) => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let Objt = { "deployed": status };
        await fetch(`${api}/rating/evaluations_delete/${Id_Evaluation}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        halcoloaderRemove();
        setDeploye(status);
    }

    const renderRowIndex = (cellData:any) => <span>{cellData.rowIndex + 1}</span>; 

    return (
        <ScrollView>
            <div className='btnAtras'>
                <Link to={'/Evaluations'}>
                    <Button className='' icon="back" text="Atras"></Button>
                </Link>
            </div>
            <div className={'content-flex dx-card responsive-paddings cardEditEvaluation'}>
                <div>
                    <TabPanel
                        id="withIconAndText"
                        width={"100%"}
                        defaultSelectedIndex={0}
                        scrollByContent={true}
                        showNavButtons={true}
                        stylingMode={"secondary"}
                        iconPosition={"start"}
                        onTitleClick={panelItemClick}
                    >
                        <Item title='Ajustes' icon='preferences'>
                            <ScrollView>
                                <div className={'form-avatar'} onClick={handleClick}>
                                    <img
                                        alt={''}
                                        className='imgDiv'
                                        src={imgPath}
                                    />
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="*"
                                        onChange={handleFileUpload}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <div style={{ margin: '25px 0px 0px 12%', width: "auto" }}>
                                    <TextBox
                                        label='Titulo'
                                        placeholder='Debes ingresar un título'
                                        value={Titulo}
                                        onValueChanged={(e) => handleTextChanged(e, "Titulo")}
                                    ></TextBox>
                                    <TextBox
                                        label='Descripción'
                                        placeholder='Debes ingresar una descripción'
                                        value={Descripcion}
                                        className='descripcionEdit'
                                        onValueChanged={(e) => handleTextChanged(e, "Descripcion")}
                                    ></TextBox>
                                    <Button
                                        style={{ margin: '0px 0px 0px 0px' }}
                                        stylingMode='contained'
                                        type='success'
                                        text='Actualizar'
                                        onClick={() => Guardar()}
                                    ></Button>
                                    <Button
                                        className='btnEditarPreguntas'
                                        stylingMode='contained'
                                        type='default'
                                        text='Editar Preguntas'
                                        onClick={() => EditForm()}
                                    ></Button>
                                    <Button
                                        className='btnDescargarTemplate'
                                        stylingMode='contained'
                                        type='danger'
                                        text='Descargar template'
                                        onClick={() => handleExport()}
                                    ></Button>
                                    <Button
                                        className='btnDeployeEvaluationTrue'
                                        stylingMode='contained'
                                        text='DESPLEGAR EVALUACIÓN'
                                        onClick={() => DeployeEvaluation(true)}
                                        visible={!deployee}
                                    ></Button>
                                    <Button
                                        className='btnDeployeEvaluationFalse'
                                        stylingMode='contained'
                                        text='RETIRAR DESPLIEGUE'
                                        onClick={() => DeployeEvaluation(false)}
                                        visible={deployee}
                                    ></Button>
                                </div>
                                <FileUploader
                                    className="uploadTemplate"
                                    multiple={false}
                                    uploadMode={"instantly"}
                                    labelText=""
                                    selectButtonText="SELECCIONAR UN TEMPLATE"
                                    onValueChanged={(files) => getFiles(files)}
                                />
                                <div className="gridCollaborators">
                                    <fieldset className="FieldsetEvaluators">
                                        <legend>Evaluadores</legend>
                                        <DataGrid
                                            className='dgvEvaluators'
                                            dataSource={dtEvaluators}
                                            height="580px"
                                            width="90%"
                                            rowAlternationEnabled={false}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            showBorders={true}
                                            onRowClick={(e) => { console.log(e) }}
                                            ref={gridRefEvaluators}
                                            paging={{ enabled: false }} // Deshabilitar paginación
                                        >
                                            <SearchPanel
                                                visible={true}
                                                width={240}
                                                placeholder="Buscar..."
                                            />
                                            <HeaderFilter><Search enabled={true} /></HeaderFilter>
                                            <Column caption="#" width={50} cellRender={renderRowIndex} alignment="center" allowSorting={false} allowFiltering={false}/>
                                            <Column dataField={"cvetra"} caption='No Empleado' visible={true} width="100px"></Column>
                                            <Column dataField={"levelEvaluator"} caption='Nivel' visible={true} width="150px"></Column>
                                            <Column dataField={"name_employee"} caption='Nombre' visible={true} width="300px"></Column>
                                        </DataGrid>
                                    </fieldset>
                                    <fieldset className="FieldsetEvaluateds">
                                        <legend>Evaluadores</legend>
                                        <DataGrid
                                            className='dgvEvaluateds'
                                            dataSource={dtEvaluateds}
                                            height="580px"
                                            width="90%"
                                            rowAlternationEnabled={false}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            showBorders={true}
                                            ref={gridRefEvaluateds}
                                            paging={{ enabled: false }} // Deshabilitar paginación
                                        >
                                            <SearchPanel
                                                visible={true}
                                                width={240}
                                                placeholder="Buscar..."

                                            />
                                            <HeaderFilter><Search enabled={true} /></HeaderFilter>
                                            <Column caption="#" width={50} cellRender={renderRowIndex} alignment="center" allowSorting={false} allowFiltering={false}/>
                                            <Column dataField={"cvetra"} caption='No Empleado' visible={true} width="100px"></Column>
                                            <Column dataField={"name_employee"} caption='Nombre' visible={true} width="300px"></Column>
                                        </DataGrid>
                                    </fieldset>
                                </div>

                            </ScrollView>
                        </Item>
                        <Item title='Resultados' icon='description'></Item>
                    </TabPanel>
                </div>
            </div>
        </ScrollView>
    );
};


const mapStateToProps = (state: RootState) => {
    return {
        select_IdEvaluation: state.Evaluations.Id_Evaluation,
        Select_Title: state.Evaluations.Title,
        Select_Description: state.Evaluations.Description,
        Select_FormId: state.Evaluations.Form_Id,
        Select_Evaluators: state.Evaluations.Evaluators,
        Select_Evaluateds: state.Evaluations.Evaluateds,
        Select_Img_name: state.Evaluations.Img_name,
        Select_Img_path: state.Evaluations.Img_path,
        Select_Order: state.Evaluations.Order,
        Select_Deploye: state.Evaluations.Deploye
    };
};

const mapDispatchToProps = (dispatch: any) => { return {}; };

export default connect(mapStateToProps, mapDispatchToProps)(EditEvaluation)