import React, { useState, useCallback, useEffect } from 'react';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader'
import List from 'devextreme-react/list';
import notify from 'devextreme/ui/notify';

interface OpcionesCorrectasProps {
    answers: any[];
    ids_CorrectAnswers: any[];
    onItemClickList: (taskId: any) => void;
}

const OpcionesCorrectas: React.FC<OpcionesCorrectasProps> = ({ answers, ids_CorrectAnswers, onItemClickList }) => {
    const [selectedIds, setSelectedIds] = useState(ids_CorrectAnswers);

    useEffect(() => {
        setSelectedIds(ids_CorrectAnswers);
    }, [ids_CorrectAnswers]);

    const onSelectedItemKeysChange = useCallback((e: any) => {
        setSelectedIds(e.value);
    }, []);

    const selectItem = (e: any) => {
        onItemClickList(e.component.option('selectedItems'));
    };

    return (
        <List
            visible={true}
            dataSource={answers}
            showSelectionControls={true}
            selectionMode={"multiple"}
            selectByClick={true}
            onItemClick={selectItem}
            displayExpr={"option"}
            keyExpr={"_id"}
            onOptionChanged={(e) => {
                if (e.name === 'selectedItemKeys') {
                    onSelectedItemKeysChange(e);
                }
            }}
            selectedItemKeys={selectedIds}
        />
    );
};

const SaveOptions = async (
    getEvaluations: () => void, 
    idPregunta: string,
    visibleRespuestasCorrectas: boolean,
    visibleValoracionMultiple: boolean,
    correctAnswers: any,
    puntosCorrectos: string,
    titleQuestion: string,
    TipoRespuesta: string,
    explicacionRespuesta: string,
    obligatorio: boolean,
    updateEvaluations: (obj:any) => void,
    GetActualQuestions: () => void
    ) => {
    try {
        halcoloaderActive();
        let dtQuestion: any = await getEvaluations();
        dtQuestion = dtQuestion.data[0];
        let arrayCorrectAnswers: any = [];

        let Pregunta: any = dtQuestion.questionnaire[0].questions.filter((item: any) => item._id === idPregunta);
        Pregunta = Pregunta[0];

        if (visibleRespuestasCorrectas) {
            if (correctAnswers.length > 0) {
                arrayCorrectAnswers = await correctAnswers;
            }
            else {
                let dtQuestion: any = await getEvaluations();
                dtQuestion = dtQuestion.data[0];

                let Pregunta: any = dtQuestion.questionnaire[0].questions.filter((item: any) => item._id === idPregunta);
                Pregunta = Pregunta[0];
                if (Pregunta.correct_answers.length > 0) {
                    arrayCorrectAnswers = await Pregunta.correct_answers;
                }
                else {
                    throw new Error("Debes Elegir al menos una respuesta correcta ");
                }
            }
        }


        let puntos: any = puntosCorrectos
        let dtQuestionObj: any = await getEvaluations();
        dtQuestionObj = dtQuestionObj.data[0];

        for (let i = 0; i < dtQuestionObj.questionnaire[0].questions.length; i++) {
            if (dtQuestionObj.questionnaire[0].questions[i]._id === idPregunta) {
                dtQuestionObj.questionnaire[0].questions[i].question = await titleQuestion;
                dtQuestionObj.questionnaire[0].questions[i].response_type = await TipoRespuesta;
                dtQuestionObj.questionnaire[0].questions[i].evaluation = await visibleRespuestasCorrectas;
                dtQuestionObj.questionnaire[0].questions[i].score = await visibleValoracionMultiple;
                dtQuestionObj.questionnaire[0].questions[i].correct_answers = await arrayCorrectAnswers;
                dtQuestionObj.questionnaire[0].questions[i].points = await parseInt(puntos);
                dtQuestionObj.questionnaire[0].questions[i].explanation = await explicacionRespuesta;
                dtQuestionObj.questionnaire[0].questions[i].mandatory = await obligatorio;
            }
        }

        updateEvaluations(dtQuestionObj);
        GetActualQuestions();
        halcoloaderRemove();
    }
    catch (error: any) {
        halcoloaderRemove();
        notify(error, 'error');
    }
}

export  { SaveOptions }
export  { OpcionesCorrectas }