import React, { useState } from 'react';
import { Button, DataGrid } from 'devextreme-react';
import { TagBoxSucursales, TagBoxAreas, CellRenderProgressBar, TextBoxEvaluations, CreeateHeadersGeneralForms, RenderHeader, CellRenderDtForm } from '../../components/PerformanceTrackingReport/PerformanceTrackingReport';
import { DataGridTypes, SearchPanel, Export, Pager, Column, MasterDetail } from "devextreme-react/data-grid"
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import * as Fnctn from '../../contexts/GenericFunctions';
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import 'bootstrap/dist/css/bootstrap.min.css';


function PerformanceTrackingReport() {
  const [codeSucursales, setCodeSucursales] = useState<any[]>([]);
  const [codeAreas, setCodeAreas] = useState<any[]>([]);
  const [nameEvaluation, setNameEvaluacion] = useState<string>("");
  const [codeEvaluacion, setCodeEvaluacion] = useState<any>();
  const [dgvDatos, setDgvDatos] = useState<any>();
  const [visibleDgvDatos, setVisibleDgvDatos] = useState<boolean>(false);

  const getIds = (list: any, tb: string) => {
    if (tb === "Evaluaciones") setCodeEvaluacion(list);
    else if (tb === "sucursales") setCodeSucursales(list);
    else if (tb === "areas") setCodeAreas(list);
  }

  const onExporting = (e: DataGridTypes.ExportingEvent, datasoruce: any) => {
    e.cancel = true;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Hoja1");

    let values: any[] = Object.values(datasoruce.data[0].questions);
    let headers = [
      "NO EMPLEADO EVALUADO",
      "NOMBRE EVALUADOR",
      "EVALUACIÓN",
      "FECHA",
      "PUNTOS TOTALES"
    ];
    headers = [...headers, ...values];
  
    const headerRow = worksheet.addRow(headers);
  
    // Estilos para los encabezados
    headerRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffcc99" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
  
    datasoruce.data.forEach((item: any) => {
      let values: any[] = Object.values(item.answers);
      let answers: any[] = [];
      values.forEach((itemAnswer: any) => answers.push(itemAnswer.option));
  
      const dataRow = worksheet.addRow([
        item.evaluated_cvetra,
        item.evaluator_name,
        item.name,
        item.create_date,
        item.total_points,
        ...answers
      ]);
  
      // Estilos para los datos
      dataRow.eachCell((cell, colNumber) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "left" };
      });
    });

  worksheet.columns.forEach((column:any) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell:any) => {
      const columnLength = cell.value ? cell.value.toString().length : 10; 
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength + 4;
  });
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver.saveAs(new Blob([buffer], { type: "application/octet-stream" }),`Resultados_${nameEvaluation}.xlsx`);
    });
  };

  const DetailGridEvalautions = (props: any, datasource: any) => {
    datasource = datasource.filter((item: any) => props.data.data.cvetra === item.evaluated_cvetra);
    let newdatasource = datasource.map((item: any) => {
      let obj = {
        ...item,
        ...item.answers,
        ...item.questions
      }
      return obj
    });

    if (newdatasource.length > 0) {
      let columns = CreeateHeadersGeneralForms(datasource);
      return (
        <DataGrid
          className='dgvResultadosDetail'
          dataSource={newdatasource}
          width="100%"
          rowAlternationEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
        >
          <Column dataField={"evaluator_name"} caption={'Evaluador'} visible={true} width={"auto"} fixed={true}></Column>
          <Column dataField={"id_form"} visible={false}></Column>
          <Column dataField={"id_form_answers"} visible={false}></Column>
          <Column dataField={"name"} visible={false}></Column>
          <Column dataField={"create_date"} caption={'Fecha de respuestas'} visible={true} width={"auto"} fixed={true} alignment='center'></Column>
          <Column dataField={"total_points"} caption={'Puntaje'} visible={true} width={"auto"} fixed={true}></Column>
          {
            columns.map((column: any, index: any) => (
              <Column
                {...column}
                allowSorting={true}
                key={index}
                customProperty={column.ResponseType}
                headerCellRender={RenderHeader}
                cellRender={(e) => CellRenderDtForm(e)}
                alignment='left'
                width={"200"}
              />
            ))
          }
          <Column dataField={"user_id"} visible={false}></Column>
        </DataGrid>
      );
    }
    else {
      return (<div></div>);
    }
  }

  const Consultar = async () => {
    halcoloaderActive();
    let api = Fnctn.GetAPI();
    let data = Fnctn.getCookie();

    let ObjtEvaluations: any = {
      "code_branches": codeSucursales,
      "ids_areas": codeAreas,
      "evaluation_id": codeEvaluacion[0],
      "user_id": data._id,
      "rol_id": data.data_role[0]._id,
      "job_code": data.job_code
    };

    let dtEvaluations: any = await fetch(`${api}/reports/tracking_performance`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtEvaluations)
    });

    dtEvaluations = await dtEvaluations.json();

    let ObjtEvaluationsDetail: any = {
      evaluation_id: codeEvaluacion[1]
    };

    let dtEvaluationsDetail: any = await fetch(`${api}/reports/detail_tracking_performance`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
      body: JSON.stringify(ObjtEvaluationsDetail)
    });

    dtEvaluationsDetail = await dtEvaluationsDetail.json();

    dtEvaluations.data.forEach((evaluation: any) => {
      let cvetra = evaluation.cvetra;
      let matchingDetails = dtEvaluationsDetail.data.filter((detail: any) => detail.evaluated_cvetra === cvetra);
      let answersTotal: number = 0;
      let evaluationTotal: number = 0;

      matchingDetails.forEach((detail: any) => {
        answersTotal += detail.total_points_answers;
        evaluationTotal = detail.total_points_evaluation;
      });

      let percentage: number = 0;
      if (evaluationTotal > 0) {
        percentage = (answersTotal / evaluationTotal) * 100 / matchingDetails.length / 100;
      }
      evaluation.percentage = percentage;
    });

    setDgvDatos(
      <DataGrid
        className='dgvResultados'
        dataSource={dtEvaluations.data}
        height="43rem"
        width="100%"
        rowAlternationEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        onExporting={(e) => onExporting(e, dtEvaluationsDetail)}
      >
        <MasterDetail
          enabled={true}
          component={(props) => DetailGridEvalautions(props, dtEvaluationsDetail.data)}
        />
        <SearchPanel
          visible={true}
          width={240}
          placeholder="Buscar"
        />
        <Column dataField={"cvetra"} caption={'NO Empleado'} visible={true} width={"auto"} alignment='center' fixed={true}></Column>
        <Column dataField={"name_employee"} caption={'Nombre'} visible={true} width={"auto"} fixed={true}></Column>
        <Column dataField={"area_name"} caption={'Area'} visible={true} width={"auto"} fixed={true}></Column>
        <Column dataField={"job_name"} caption={'Puesto trabajo'} visible={true} width={"auto"} fixed={true}></Column>
        <Column dataField={"percentage"} caption={'Porcentaje'} visible={true} width={"auto"} alignment='center' fixed={true} format={{ type: 'percent', precision: 2 }}></Column>
        <Column dataField={"pending_evaluations"} caption={'Evaluaciones pendientes'} visible={true} width={"auto"} alignment='center' fixed={true}></Column>
        <Column dataField={"evaluations"} caption={'Evaluaciones totales'} visible={true} width={"auto"} alignment='center' fixed={true} cellRender={(e) => CellRenderProgressBar(e, "Progress")}></Column>
        <Export enabled={true} allowExportSelectedData={true} />
        <Pager showPageSizeSelector={true} allowedPageSizes={dtEvaluations.data.length > 1000 ? [100, 150, 1000] : [100, 150, 'all']} />
      </DataGrid>
    );
    setVisibleDgvDatos(true);
    halcoloaderRemove();
  };

  const getEvaluationName = (list: any) => {
    setNameEvaluacion(list);
  }

  return (
    <React.Fragment>
      <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 45px", width: "95%" }}>
        <div style={{ display: "flex" }}>
          <TextBoxEvaluations 
            getEvaluationName={getEvaluationName} 
            getIds={getIds}
          ></TextBoxEvaluations>
          <TagBoxSucursales getIds={getIds}></TagBoxSucursales>
          <TagBoxAreas getIds={getIds}></TagBoxAreas>
          <Button
            className='btnConsultar'
            stylingMode='contained'
            text='Consultar'
            onClick={() => Consultar()}
          ></Button>
        </div>
        {visibleDgvDatos && dgvDatos}
      </div>
    </React.Fragment>
  )
}

export default PerformanceTrackingReport; 