import React, { useState, useRef } from "react"
import { Button, TextBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import * as Fnctn from '../../contexts/GenericFunctions';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader'
import * as FireBase from '../../contexts/FirebaseActions'
interface IProps {
    handlePopupHidden: () => void;
}
const NuevaCategoria: React.FC<IProps> = ({ handlePopupHidden }) => {
    let api = Fnctn.GetAPI();

    const [base64Image, setBase64Image] = useState<string>();
    const [FotoTarea, setFotoTarea] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [Titulo, setTitulo] = useState<string>('');

    const handleTextChanged = (e: any, Textbox: string = "") => {
        if (Textbox === "Titulo") setTitulo(e.value);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setFotoTarea(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String: string = reader.result!.toString();
                setBase64Image(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const getLastIndex = async () => {
        const data = Fnctn.getCookie();
        const dtresult = await fetch(`${api}/catalogs/categories`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        const dtCategories = await dtresult.json();
        let max: number = -1
        dtCategories.data.forEach((element:any) => {
            if (max < element.order){
                max = element.order
            }
        });

        return max + 1
    }

    const Guardar = async () => {
        try {
            if (!Fnctn.NullUndefinedEmpty(Titulo)) {
                halcoloaderActive();
                const data = Fnctn.getCookie();
                let index = await getLastIndex();
                if (!Fnctn.NullUndefinedEmpty(FotoTarea)) {
                    let IconoTarea = await FireBase.uploadFile(FotoTarea, FireBase.enumPath.ICONOS_CATEGORIAS);
                    let Objt = {
                        "name": Titulo
                        , "description": ""
                        , "img_path": IconoTarea.PathFireBase
                        , "img_name": IconoTarea.NameFirebase
                        , "order": index
                    }
                    let dtCategories = await fetch(`${api}/catalogs/categories`,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${data.token}`
                            },
                            body: JSON.stringify(Objt)
                        });

                    console.log(await dtCategories.json())
                    // setLoading(false);
                    halcoloaderRemove();
                    notify("Categoría agregada correctamente", "success")
                }
                else{
                    halcoloaderRemove();
                    notify("Debes agregar una imagen para el ícono de la categoría", "error")
                }
            }
            else{
                notify("Debes agregar un título a la categoría", "error")
            }
        }
        catch (error) {
            notify(error, 'error');
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'}>
                <div style={{ margin: '0px 0px 40px 0px' }}><label style={{ fontSize: '25px' }}>Nueva categoría</label></div>
                <div>
                    <div className={'form-avatar'} style={{ cursor: 'pointer', borderRadius: '10%' }} onClick={handleClick}>
                        <img
                            alt={''}
                            src={base64Image}
                            style={{ width: '100%', height: '100%', objectFit: 'contain', borderColor: "#000" }}
                        />
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <div style={{ margin: '0px 0px 0px 15%', width: "80%" }}>
                        <TextBox
                            label='Titulo'
                            placeholder='Debes ingresar un título'
                            onValueChanged={(e) => handleTextChanged(e, "Titulo")}
                        ></TextBox>
                        <Button
                            style={{ margin: '15px 0px 0px 0px' }}
                            stylingMode='contained'
                            type='success'
                            text='Guardar'
                            onClick={() => Guardar()}
                        ></Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default NuevaCategoria;