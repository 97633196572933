let halcoLoader = `
    <div class="halcoloader-logo">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="425.7" height="311.523" viewBox="0 0 425.7 311.523">
  <defs>
    <linearGradient id="linear-gradient" y1="0.752" x2="1" y2="0.752" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#e31d1a"/>
      <stop offset="1" stop-color="#e31d1a"/>
    </linearGradient>
  </defs>
  <g id="Grupo_3" data-name="Grupo 3" transform="translate(-35.454 -86.877)" style="isolation: isolate">
    <g id="Grupo_1" data-name="Grupo 1" style="isolation: isolate">
      <path id="Trazado_13" class="th_letras" data-name="Trazado 1" d="M63.547,371.161l-3.117,6.447H54.41L44.761,398.4H35.454l9.734-20.792H38.314l3.075-6.447Z"/>
      <path id="Trazado_14" class="th_letras" data-name="Trazado 2" d="M87.032,371.161l-3.2,6.447H73.967l-2.092,4.7h7.044l-2.86,6.148H69.015l-1.836,3.8H79.261L76.187,398.4H55.011c.171-.64,1.921-4.44,5.209-11.356,3.33-6.959,5.037-10.588,5.166-10.887a3.8,3.8,0,0,0,.256-1.281c0-.939-.684-2.007-2.05-3.245l.214-.47Z"/>
      <path id="Trazado_15" class="th_letras" data-name="Trazado 3" d="M88.828,371.161h19.3c3.459,0,5.636,1.495,5.636,4.228a10.746,10.746,0,0,1-1.024,4.055l-.9,2.135-1.11,2.306-.9,1.878a9.675,9.675,0,0,1-7.258,5.806L106.8,398.4h-9.99l-2.733-6.7-1.622-.085L89.34,398.4H80.033c6.575-13.961,10.033-21.346,10.375-22.243a3.8,3.8,0,0,0,.256-1.281c0-.939-.684-2.007-2.05-3.245Zm15.071,7.045a1.006,1.006,0,0,0-1.153-1.11h-3.5L94.933,386.4h4.142a1.98,1.98,0,0,0,1.878-1.366A59.85,59.85,0,0,0,103.9,378.206Z"/>
      <path id="Trazado_16" class="th_letras" data-name="Trazado 4" d="M117.693,371.161h19.3c3.458,0,5.635,1.495,5.635,4.228a10.727,10.727,0,0,1-1.024,4.055l-.9,2.135-1.11,2.306-.9,1.878a9.678,9.678,0,0,1-7.259,5.806l4.227,6.831h-9.99l-2.733-6.7-1.622-.085-3.116,6.788H108.9c6.575-13.961,10.033-21.346,10.375-22.243a3.8,3.8,0,0,0,.256-1.281c0-.939-.683-2.007-2.05-3.245Zm15.071,7.045a1.006,1.006,0,0,0-1.153-1.11h-3.5L123.8,386.4h4.142a1.98,1.98,0,0,0,1.878-1.366A59.85,59.85,0,0,0,132.764,378.206Z"/>
      <path id="Trazado_17" class="th_letras" data-name="Trazado 5" d="M159.708,371.161,147.07,398.4h-9.307c6.575-13.961,10.033-21.346,10.375-22.243a3.8,3.8,0,0,0,.256-1.281c0-.939-.684-2.007-2.05-3.245l.214-.47Z"/>
      <path id="Trazado_18" class="th_letras" data-name="Trazado 6" d="M184.13,371.161l-3.116,6.447h-6.02L165.345,398.4h-9.307l9.734-20.792H158.9l3.074-6.447Z"/>
      <path id="Trazado_19" class="th_letras" data-name="Trazado 7" d="M202.321,391.7c-2.22,4.91-5.337,6.7-9.948,6.7h-9.862a5.287,5.287,0,0,1-3.629-1.323,4.58,4.58,0,0,1-1.494-3.5,6.176,6.176,0,0,1,.683-2.775l6.4-13.7a11.31,11.31,0,0,1,2.69-3.842c2.049-1.836,3.714-2.093,6.617-2.093H203.9c3.2,0,5.209,1.623,5.209,4.142a6.58,6.58,0,0,1-.6,2.6Zm-3.245-13.576c0-.683-.384-1.025-1.2-1.025h-2.6c-1.366,0-1.921.6-2.6,2.134-3.714,8.156-5.593,12.339-5.593,12.553q0,1.025,1.281,1.025h2.391c1.366,0,1.921-.427,2.732-2.263C197.2,382.519,199.076,378.377,199.076,378.121Z"/>
      <path id="Trazado_20"  class="th_letras" data-name="Trazado 8" d="M212.871,371.161h19.3c3.458,0,5.636,1.495,5.636,4.228a10.732,10.732,0,0,1-1.025,4.055l-.9,2.135-1.11,2.306-.9,1.878a9.678,9.678,0,0,1-7.258,5.806l4.227,6.831h-9.991l-2.732-6.7-1.622-.085-3.117,6.788h-9.308c6.575-13.961,10.033-21.346,10.375-22.243a3.778,3.778,0,0,0,.256-1.281c0-.939-.683-2.007-2.049-3.245Zm15.07,7.045a1.006,1.006,0,0,0-1.152-1.11h-3.5l-4.312,9.307h4.141A1.981,1.981,0,0,0,225,385.037,59.935,59.935,0,0,0,227.941,378.206Z"/>
      <path id="Trazado_31" class="th_letras" data-name="Trazado 9" d="M254.885,371.161,242.248,398.4H232.94c6.575-13.961,10.033-21.346,10.375-22.243a3.8,3.8,0,0,0,.256-1.281c0-.939-.683-2.007-2.05-3.245l.214-.47Z"/>
      <path id="Trazado_32" class="th_letras" data-name="Trazado 10" d="M274.781,391.7c-2.219,4.91-5.336,6.7-9.947,6.7h-9.862a5.283,5.283,0,0,1-3.629-1.323,4.58,4.58,0,0,1-1.494-3.5,6.176,6.176,0,0,1,.683-2.775l6.4-13.7a11.322,11.322,0,0,1,2.689-3.842c2.05-1.836,3.715-2.093,6.618-2.093h10.118c3.2,0,5.209,1.623,5.209,4.142a6.6,6.6,0,0,1-.6,2.6Zm-3.244-13.576c0-.683-.385-1.025-1.195-1.025h-2.605c-1.366,0-1.922.6-2.6,2.134-3.715,8.156-5.593,12.339-5.593,12.553q0,1.025,1.28,1.025h2.392c1.366,0,1.921-.427,2.732-2.263C269.658,382.519,271.537,378.377,271.537,378.121Z"/>
    </g>
    <g id="Grupo_2"  data-name="Grupo 2" style="isolation: isolate">
      <path id="Trazado_21" data-name="Trazado 11" d="M325.969,371.161,313.331,398.4h-9.308l3.886-8.24H302.4l-3.8,8.24h-9.308c6.575-13.961,10.033-21.346,10.375-22.243a3.778,3.778,0,0,0,.256-1.281c0-.939-.683-2.007-2.049-3.245l.213-.47h13.15l-6.233,13.449h5.508l3.884-8.453a3.778,3.778,0,0,0,.256-1.281c0-.939-.682-2.007-2.049-3.245l.213-.47Z" fill="#e31d1a"/>
      <path id="Trazado_22" data-name="Trazado 12" d="M327.936,371.161H347.4c3.458,0,5.636,1.495,5.636,4.228a8.222,8.222,0,0,1-.811,3.33L343.306,398.4h-9.478l3.116-6.617h-5.465l-3.031,6.617h-9.307c6.575-13.961,10.033-21.346,10.375-22.243a3.774,3.774,0,0,0,.255-1.281c0-.939-.682-2.007-2.048-3.245Zm14.815,8.027a2.237,2.237,0,0,0,.256-.982,1.006,1.006,0,0,0-1.153-1.11h-3.5l-4.227,9.136h5.379Z" fill="#e31d1a"/>
      <path id="Trazado_23" data-name="Trazado 13" d="M370.506,371.161l-9.99,21.091H370.8L367.73,398.4H348.348c6.575-13.961,10.033-21.346,10.375-22.243a3.8,3.8,0,0,0,.256-1.281c0-.939-.684-2.007-2.05-3.245l.214-.47Z" fill="#e31d1a"/>
      <path id="Trazado_14" class="th_letras" data-name="Trazado 14" d="M388.781,388.666h9.308L396.68,391.7c-2.263,4.91-5.38,6.7-9.949,6.7h-9.093a5.287,5.287,0,0,1-3.629-1.323,4.58,4.58,0,0,1-1.494-3.5,6.19,6.19,0,0,1,.683-2.775l6.4-13.7c1.153-2.434,2.433-4.013,3.842-4.782a11.412,11.412,0,0,1,5.465-1.153h9.351c3.2,0,5.208,1.623,5.208,4.142a6.565,6.565,0,0,1-.6,2.6l-2.775,6.106h-9.222a58.065,58.065,0,0,0,2.52-5.807,1.088,1.088,0,0,0-1.154-1.11H390.4c-1.366,0-1.793.512-2.646,2.262-3.715,8.113-5.593,12.254-5.593,12.467,0,.641.427.983,1.323.983h1.451c1.41,0,1.964-.385,2.819-2.092Z" fill="#e31d1a"/>
      <path id="Trazado_25" data-name="Trazado 15" d="M425.074,391.7c-2.22,4.91-5.336,6.7-9.947,6.7h-9.862a5.283,5.283,0,0,1-3.629-1.323,4.577,4.577,0,0,1-1.494-3.5,6.175,6.175,0,0,1,.682-2.775l6.4-13.7a11.322,11.322,0,0,1,2.689-3.842c2.05-1.836,3.715-2.093,6.618-2.093h10.118c3.2,0,5.209,1.623,5.209,4.142a6.6,6.6,0,0,1-.6,2.6Zm-3.244-13.576c0-.683-.385-1.025-1.195-1.025H418.03c-1.366,0-1.922.6-2.6,2.134-3.715,8.156-5.593,12.339-5.593,12.553q0,1.025,1.28,1.025h2.392c1.366,0,1.921-.427,2.732-2.263C419.951,382.519,421.83,378.377,421.83,378.121Zm8.88-13.919-7.728,4.782h-5.421l3.116-4.782Z" fill="#e31d1a"/>
      <path id="Trazado_26" data-name="Trazado 16" d="M455.9,371.161c3.2,0,5.251,1.623,5.251,4.142a6.3,6.3,0,0,1-.639,2.6L451.036,398.4h-9.307q9.35-19.917,9.35-20.237a1.09,1.09,0,0,0-1.2-1.067H446l-9.862,21.3h-9.308c6.575-13.961,10.033-21.346,10.375-22.243a3.778,3.778,0,0,0,.256-1.281c0-.939-.683-2.007-2.049-3.245l.213-.47Z" fill="#e31d1a"/>
    </g>
  </g>
  <path id="Trazado_1" class="logo_territorio_halcon" data-name="Trazado 17" d="M309.1,184.2h-.5a96.565,96.565,0,0,0-21.2,2.9c3.2,1.5,5.9,2.7,7.8,3.4a6.118,6.118,0,0,1,1.2.5c.4.2,1,.4,1.6.7,10.5,4.3,39.6,18.3,55.6,47.6,7.1,13.5,7.6,16.8,5.7,18.2-2,1.4-6,3.9-12.8,1.9,0,0,6.2.7,7.2-5.2.8-4.6-15.2-28.4-39.5-44.3h0a17.4,17.4,0,0,0-1.8-1.1c-3.9-2.4-15.2-8.6-39.9-19.1-.9-.4-1.7-.8-2.5-1.1a.31.31,0,0,1-.2-.1c-1.1-.5-2.2-1.1-3.2-1.6-5.5-2.9-16-8.7-20.8-13.1a81.061,81.061,0,0,1-9.2-8.8s4.4,31.1,11,44.2c-23.4-37.1-27.8-42.2-27.8-42.2s-72-29.4-114.9-79.8c-2-5,1.6,49.1,74,83.6-36.1-9-50.8-16.2-50.8-16.2l12,25.6s32.8,16.9,57.7,19.2c-23.9,6.1-48.4,0-48.4,0l12.8,20.3s18.2,5.9,53.3,9.8c-4.9,6.8-40,6.7-40,6.7s9.5,24.5,50.2,19.6c5.5,2.2-29.4,11.6-29.4,11.6a187.692,187.692,0,0,0,41.4,40.2,82.179,82.179,0,0,0,10.2,14c0-5.7,1.2-56.7,34.2-57.7,10.3-.3,17.2.3,22,1.4h0a19.979,19.979,0,0,1,3.4,1c3.8.9,14.5.4,20.9-.9-5.9,2.1-13.1,5.6-14.3,7.2v.3s16.8,4.6,36.2,38.6C370,294.7,358,264,358,264s10.7,10.4,9.8,24.6-7.8,30-21.2,37.3c-8.8-28.6-27.7-48.9-44.7-52.6,0,0-40.8-15.3-41.8,34.8,1.5,22.6,4.2,27.8,4.4,28.2A81.062,81.062,0,0,0,306,349h3.1a82.35,82.35,0,0,0,0-164.7m-53.7,58.4c-4,.3-4.8.4-5,.4h0l4-2.5s15.6-9.5,36,4a89.594,89.594,0,0,0-14.8-15s12.9,4,16.6,9.9c2.1,5.9,12.9,14.3,22.2,16-18.3-1.2-27.1-15.7-59-12.8M320,255.4a15.37,15.37,0,0,1-1.9-1.7l-2.5-4.1-.2-.2a13.04,13.04,0,0,1-7.5-1.6l-1.7-9.4,7.8,6.3c-.5-1.5-1-2.9-1.5-4.3-.6-1.2-1.2-2.4-1.8-3.4a.349.349,0,0,0-.1-.2h0C301.7,220.8,293,214,293,214s18.5,7.9,27.8,25.4c9.4,17.5,19.5,19.7,19.5,19.7s-12.7,2.5-20.3-3.5m35.1,25.1a13.762,13.762,0,0,0-6.8-12.1c1.1-1,5.5-1.7,5.5-1.7s3.1,7.4,1.3,13.8" transform="translate(-35.454 -86.877)" fill="url(#linear-gradient)"/>
</svg>

</div>`;                                    


export const halcoloaderActive = () => {
    var halcoloader = document.querySelector('.halcoloader');
    
    document.querySelector('.halcoloader').innerHTML = halcoLoader
    
    return halcoloader.classList.add('active')
};

export const halcoloaderRemove = () => {
    var halcoloader = document.querySelector('.halcoloader');
    
    return halcoloader.classList.remove('active')
};

