import { Component } from 'react';
import { SelectBox } from 'devextreme-react';
// import { LoadPanel } from 'devextreme-react/load-panel';
import * as Fnctn from '../contexts/GenericFunctions';

interface IProps{
    onSelectionChanged:any,
    valueCategory:string
}
interface IState {
    Categories: any,
    handleSelectionChanged: any,
    categoryselected: any
}
class Categories extends Component<IProps, IState> {
    constructor(props: IProps){
        super(props);
        this.state = {
            Categories: [],
            handleSelectionChanged: [],
            categoryselected: this.props.valueCategory
        }
        // console.log("propes", this.props)
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = async () => {
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();
        const dtresult = await fetch(`${api}/catalogs/categories/?onboarding=false`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        const dtCategories = await dtresult.json()
        const sortedItems = await [...dtCategories.data[0]].sort((a, b) => a.order - b.order);
        this.setState({Categories: sortedItems})
    }

    ItemRenderList = (item: {name:string, img_path:string}) => {
        return(
            <div className='listCategoriesContent'>
                <img src={item.img_path} className='ImgCategories' alt='Imagen de categoría'/>
                <label className='lbTitleCategories'>{item.name}</label>
            </div>
        )
    }

    handleSelectionChanged = (e: any) => {
        this.props.onSelectionChanged(e.selectedItem._id);
        this.setState({categoryselected: e.selectedItem._id});
    }

    render() {
        return (
            <div>
                <SelectBox
                    items={this.state.Categories}
                    displayExpr="name"
                    valueExpr="_id"
                    label='Categoría'
                    width={"30%"}
                    itemRender={this.ItemRenderList}
                    onSelectionChanged={this.handleSelectionChanged}
                    value={this.state.categoryselected}
                />
            </div>
        );
    }
}

export default Categories;
