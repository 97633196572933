import React, { useState, useEffect } from 'react';
import { TextBox, SelectBox, List } from 'devextreme-react';
import * as Fnctn from '../../contexts/GenericFunctions'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import notify from 'devextreme/ui/notify';

interface PropsCantidad {
    CantidadVal: any;
    handleTextChanged: (e: any, Textbox: string) => void;
}

const Cantidad: React.FC<PropsCantidad> = ({ CantidadVal, handleTextChanged }) => {
    const [cantidad, setCantidad] = useState<any>();

    useEffect(() => {
        if (cantidad !== CantidadVal) {
            setCantidad(CantidadVal);
        }
    }, [CantidadVal]);

    const handleSelectionChange = (e: any, Textbox: string = "") => {
        handleTextChanged(e, Textbox);
    };

    return (
        <TextBox
            label='Cantidad'
            value={cantidad}
            onValueChanged={(e:any) => handleSelectionChange(e, "Cantidad")}
            width={"10%"}
            style={{ margin: '0px 20px 0px 0px' }}
        ></TextBox>
    );
};

interface PropsTiempo {
    TiempoVal: any;
    onValueChangedList: (e: any, tiempo: string) => void;
}

const Tiempo: React.FC<PropsTiempo> = ({ TiempoVal, onValueChangedList }) => {
    const [tiempo, setTiempo] = useState<any>();

    useEffect(() => {
        if (tiempo !== TiempoVal) {
            setTiempo(TiempoVal);
        }
    }, [TiempoVal]);

    const handleSelectionChange = (e: any) => {
        onValueChangedList(e, "Tiempo");
    };

    const ItemsTiempo = [
        { Codigo: "D", Nombre: 'Días' },
        { Codigo: "S", Nombre: 'Semanas' },
        { Codigo: "A", Nombre: 'Años' }
    ];

    return (
        <SelectBox
            items={ItemsTiempo}
            displayExpr="Nombre"
            valueExpr="Codigo"
            label='Tiempo'
            onSelectionChanged={handleSelectionChange}
            width={"30%"}
            style={{ margin: '0px 20px 0px 0px' }}
            value={tiempo}
        />
    );
};

interface PropsSecuencia {
    SecuenciaVal: any;
    onValueChangedList: (e: any, secuencia: string) => void;
}

const Secuencia: React.FC<PropsSecuencia> = ({ SecuenciaVal, onValueChangedList }) => {
    const [secuencia, setSecuencia] = useState<any>();

    useEffect(() => {
        if (secuencia !== SecuenciaVal) {
            setSecuencia(SecuenciaVal);
        }
    }, [SecuenciaVal]);

    const handleSelectionChange = (e: any) => {
        onValueChangedList(e, "Secuencia");
    };

    const ItemsSecuencia = [
        { Codigo: "before", Nombre: 'Antes' },
        { Codigo: "after", Nombre: 'Después' }
    ];

    return (
        <SelectBox
            value={secuencia}
            items={ItemsSecuencia}
            displayExpr="Nombre"
            valueExpr="Codigo"
            label='Secuencia'
            onSelectionChanged={(e) => handleSelectionChange(e)}
            width={"30%"}
        />
    );
};

interface PropsNotificaciones {
    NotificacionesVal: any;
    onValueChangedList: (e: any, notifiacion: string) => void;
}

const Notificaciones: React.FC<PropsNotificaciones> = ({ NotificacionesVal, onValueChangedList }) => {
    const [notificaciones, setNotificaciones] = useState<any>();

useEffect(() => {
    if (notificaciones !== NotificacionesVal) {
        setNotificaciones(NotificacionesVal);
    }
}, [NotificacionesVal]);

const handleSelectionChange = (e: any) => {
    onValueChangedList(e, "Notificacion");
};

const ItemsNotificacion = [
    { Codigo: false, Nombre: 'No notificar' },
    { Codigo: true, Nombre: 'Notificar' }
  ];

    return (
        <SelectBox
    value={notificaciones}
    items={ItemsNotificacion}
    displayExpr="Nombre"
    valueExpr="Codigo"
    label='Notificación'
    onSelectionChanged={(e) => handleSelectionChange(e)}
    width={"30%"}
/>
    );
};

interface PropsAudiencia {
    props: any,
    selectedItems: any
    getIds: (e: any, list: string) => void;
}

let sgAreas: [] = []
    , sgBranches: [] = []
    , sgCompanies: [] = []
    // , selectedItems: [] = []
    , segmentacion: any = {
        areas: [],
        branches: [],
        companies: [],
        type_collaborators: []
    };
let areas: any = [], branches: any = [], companies: any = [];
const Audiencia: React.FC<PropsAudiencia> = ({ props, selectedItems, getIds }) => {
    const [dtArea, setDtArea] = useState<any>([]);
    const [dtEmpresa, setDtEmpresa] = useState<any>([]);
    const [dtSucursal, setDtSucursal] = useState<any>([]);
    const [checkedItems, setcheckedItem] = useState<any>();
    // const [areasA, setAreas] = useState<any>();
    const [dataSource, setdataSource] = useState<any>();
    const [list, setlist] = useState<any>();
    const [listTypeA, setlistType] = useState<any>();
    const [selectedIds, setSelectedIds] = useState<any>();

    let GetAudiencias = async () => {
        try {
            let api = Fnctn.GetAPI()
            // halcoloaderActive();
            const data = Fnctn.getCookie();
            let dtresponseArea = await fetch(`${api}/catalogs/areas`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
            dtresponseArea = await dtresponseArea.json();
            setDtArea(dtresponseArea);
            let dtresponseSucursal = await fetch(`${api}/catalogs/branches`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
            dtresponseSucursal = await dtresponseSucursal.json()
            setDtSucursal(dtresponseSucursal);
            let dtresponseEmpresa = await fetch(`${api}/catalogs/companies`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
            dtresponseEmpresa = await dtresponseEmpresa.json()
            setDtEmpresa(dtresponseEmpresa);
            // halcoloaderRemove();
            return {
                areas: dtresponseArea,
                sucursal: dtresponseSucursal,
                empresa: dtresponseEmpresa
            }
            // itemRenderAccordio(listTypeA)
        }
        catch (error) {
            halcoloaderRemove();
            notify(error, "error");
        }
    };

    const handleSelectionChanged = (e: any, list: string) => {
        if (list === "areas") sgAreas = e.component.option('selectedItems');
        else if (list === "branches") sgBranches = e.component.option('selectedItems');
        else if (list === "companies") sgCompanies = e.component.option('selectedItems');

        if (list === "areas") {
            sgAreas.forEach((item: any) => areas.push(item._id));
            setSelectedIds(areas)
        }
        else {
            areas = segmentacion.areas
        }
        if (list === "branches") {
            sgBranches.forEach((item: any) => branches.push(item._id));
            setSelectedIds(branches)
        }
        else {
            branches = segmentacion.branches
        }

        if (list === "companies") {
            sgCompanies.forEach((item: any) => companies.push(item._id));
            setSelectedIds(companies)
        }
        else {
            companies = segmentacion.companies
        }

        segmentacion = {
            "areas": areas,
            "branches": branches,
            "companies": companies,
            "type_collaborators": []
        };
        // getIds(segmentacion);
    };

    const itemRenderAccordio  = async (itemData: any) => {
        let audiencia:any = await GetAudiencias()

        if (itemData.Codigo === "areas") {
            let areasA = areas
            setdataSource(audiencia.areas.data);
            setlist("areas");
            if (!Fnctn.NullUndefinedEmpty(areasA) && areasA.length > 0) {
                if (props.Select_IdTarea !== "-1") {
                    setSelectedIds(Fnctn.NullUndefinedEmpty(areasA) ? [] : areasA)
                }
                else {
                    setSelectedIds([])
                }
            }
        }
        else if (itemData.Codigo === "branches") {
            let branchesA = branches
            setdataSource(audiencia.sucursal.data)
            setlist("branches");
            if (!Fnctn.NullUndefinedEmpty(branchesA) && branchesA.length > 0) {
                if (props.Select_IdTarea !== "-1") {
                    setSelectedIds(Fnctn.NullUndefinedEmpty(branchesA) ? [] : branchesA)
                }
                else {
                    setSelectedIds([])
                }
            }
        }
        else if (itemData.Codigo === "companies") {
            let companiesA = companies
            setdataSource(audiencia.empresa.data);
            setlist("companies");
            if (!Fnctn.NullUndefinedEmpty(companiesA) && companiesA.length > 0) {
                if (props.Select_IdTarea !== "-1") {
                    setSelectedIds(Fnctn.NullUndefinedEmpty(companiesA) ? [] : companiesA);
                }
                else {
                    setSelectedIds([]);
                }
            }
        }
    };

    const itemclick = (e:any, list:any) => {
        let arrayIds:any = [];
        e.component.option('selectedItems').forEach((element:any) =>{
            arrayIds.push(element._id)
        });
        getIds(arrayIds, list);
    }

    useEffect(() => {
        if(props.Codigo === "areas"){
            setSelectedIds(selectedItems.areas);
        }
        else if(props.Codigo === "branches"){
            setSelectedIds(selectedItems.branches);
        }
        else if(props.Codigo === "companies"){
            setSelectedIds(selectedItems.companies);
        }
        itemRenderAccordio(props)
    }, []);

    return (
        <List
            dataSource={dataSource}
            height={400}
            showSelectionControls={true}
            selectionMode={"multiple"}
            selectByClick={true}
            displayExpr={"name"}
            keyExpr={"_id"}
            onSelectionChanged={(e) => handleSelectionChanged(e, list)}
            onItemClick={(e:any) => itemclick(e, list)}
            selectedItemKeys={selectedIds}
        ></List>
    );
};

export { Cantidad };
export { Tiempo };
export { Secuencia };
export { Notificaciones };
export { Audiencia };
