export const GetAPI = () => {
    return `https://seahorse-app-g4bew.ondigitalocean.app`
    // return `http://localhost:3001`
}

export const getCookie = () => {
    const cookieName = "dtUsuario=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            const jsonValue = cookie.substring(cookieName.length, cookie.length);
            return JSON.parse(jsonValue);
        }
    }
    return null;
}

export const NullUndefinedEmpty = (variable: any) => {
    let val: boolean = true;
    if (variable === null) val = true;
    else if (variable === undefined) val = true;
    else if (variable === "") val = true;
    else val = false;
    return val;
}

export const GetCategories = async () => {
    const data = getCookie();
    const api = GetAPI()
    const dtresult = await fetch(`${api}/catalogs/categories`, { method: 'GET', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${data.token}` } });
    let categorias = await dtresult.json();
    return categorias.data;
}

export const GenerarUUID = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        ((+c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> (+c / 4)).toString(16)
    );
};

export const OpenFile = (url:string) => {
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
}