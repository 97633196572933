
import React from 'react';
import { TabPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/tab-panel';
import Get_Evaluations_User from '../../components/Evaluations/DataEvaluation_User'
import '../Evaluations/EvaluationPg.scss'

export default function EvaluationPg() {
    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings ContenedorEvaluations'}>
                <TabPanel
                    className="dx-theme-background-color TabPanelTareas"
                    showNavButtons={true}
                    animationEnabled={true}
                    swipeEnabled={true}
                    tabsPosition='top'
                    stylingMode='primary'
                    iconPosition='top'
                    width={"100%"}
                >
                    <Item title='Todas' icon='description'>
                        <Get_Evaluations_User status={["Not Started", "In Progress"]}></Get_Evaluations_User>
                    </Item>
                    <Item title='Contestadas' icon='taskcomplete'>
                        <Get_Evaluations_User status={["Completed"]}></Get_Evaluations_User>
                    </Item>
                </TabPanel>
            </div>
        </React.Fragment>
    )
}