export const SET_EVALUATION_ID = "SET_EVALUATION_ID";
export const SET_EVALUATION_TITLE = "SET_EVALUATION_TITLE";
export const SET_EVALUATION_DESCRIPTION = "SET_EVALUATION_DESCRIPTION";
export const SET_EVALUATION_EVALUATORS = "SET_EVALUATION_EVALUATORS";
export const SET_EVALUATION_EVALUATEDS = "SET_EVALUATION_EVALUATEDS";
export const SET_EVALUATION_FORM_ID = "SET_EVALUATION_FORM_ID";
export const SET_EVALUATION_IMG_NAME = "SET_EVALUATION_IMG_NAME";
export const SET_EVALUATION_IMG_PATH = "SET_EVALUATION_IMG_PATH";
export const SET_EVALUATION_ORDER = "SET_EVALUATION_ORDER";
export const SET_EVALUATION_DEPLOYE = "SET_EVALUATION_DEPLOYE";

export const set_evaluation_id = (id: string) => {
    return{
        type: SET_EVALUATION_ID,
        payload: id
    }
}

export const set_evaluation_img_name = (img_name: string) => {
    return{
        type: SET_EVALUATION_IMG_NAME,
        payload: img_name
    }
}

export const set_evaluation_img_path = (img_path: string) => {
    return{
        type: SET_EVALUATION_IMG_PATH,
        payload: img_path
    }
}

export const set_evaluation_title = (title: string) => {
    return{
        type: SET_EVALUATION_TITLE,
        payload: title
    }
}

export const set_evaluation_description = (description: string) => {
    return{
        type: SET_EVALUATION_DESCRIPTION,
        payload: description
    }
}

export const set_evaluation_evaluators = (evaluators: any[]) => {
    return{
        type: SET_EVALUATION_EVALUATORS,
        payload: evaluators
    }
}

export const set_evaluation_form_id = (form_id: any) => {
    return{
        type: SET_EVALUATION_FORM_ID,
        payload: form_id
    }
}

export const set_evaluation_evaluateds = (evaluateds: any[]) => {
    return{
        type: SET_EVALUATION_EVALUATEDS,
        payload: evaluateds
    }
}

export const set_evaluation_order = (order: number) => {
    return{
        type: SET_EVALUATION_ORDER,
        payload: order
    }
}

export const set_evaluation_deploye = (deploye: boolean) => {
    return{
        type: SET_EVALUATION_DEPLOYE,
        payload: deploye
    }
}