export const SET_TAREA_ID = "SET_TAREA_ID";
export const SET_TAREA_TITULO = "SET_TAREA_TITULO";
export const SET_TAREA_IMG = "SET_TAREA_IMG";
export const SET_TAREA_IMGNAME = "SET_TAREA_IMGNAME";
export const SET_TAREA_CONTENIDO = "SET_TAREA_CONTENIDO";
// export const SET_TAREA_CONTENIDO = "SET_TAREA_CONTENIDO"; //Para archivos
export const SET_TAREA_VENCIMIENTO = "SET_TAREA_VENCIMIENTO";
export const SET_TAREA_CANTIDAD = "SET_TAREA_CANTIDAD";
export const SET_TAREA_TIEMPO = "SET_TAREA_TIMEPO";
export const SET_TAREA_SECUENCIA = "SET_TAREA_SECUENCIA";
export const SET_TAREA_CATEGORIA = "SET_TAREA_CATEGORIA";
export const SET_TAREA_NOTIFICACION = "SET_TAREA_NOTIFICACION";
export const SET_TAREA_AUTOASIGNACION = "SET_TAREA_AUTOASIGNACION";
export const SET_TAREA_TIPOASIGNACION = "SET_TAREA_TIPOASIGNACION";
export const SET_TAREA_SEGMENTACION = "SET_TAREA_SEGMENTACION";
export const SET_TAREA_SEGMENTACIONTYPE = "SET_TAREA_SEGMENTACIONTYPE"
export const SET_TAREA_FROM = "SET_TAREA_FROM"
export const SET_TAREA_TO = "SET_TAREA_TO"
export const SET_TAREA_EVALUATION_ID = "SET_TAREA_EVALUATION_ID"
export const SET_TAREA_ATTACHMENTS = "SET_TAREA_ATTACHMENTS"
export const SET_TAREA_ORDER = "SET_TAREA_ORDER"




export const set_tarea_id = (id: string) => {
    return{
        type: SET_TAREA_ID,
        payload: id
    }
}

export const set_tarea_titulo = (titulo: string) => {
    return{
        type: SET_TAREA_TITULO,
        payload: titulo
    }
}

export const set_tarea_img = (img: string) => {
    return{
        type: SET_TAREA_IMG,
        payload: img
    }
}

export const set_tarea_imgname = (imgname: string) => {
    return{
        type: SET_TAREA_IMGNAME,
        payload: imgname
    }
}

export const set_tarea_contenido = (contenido: string) => {
    return{
        type: SET_TAREA_CONTENIDO,
        payload: contenido
    }
}

export const set_tarea_vencimiento = (vencimiento: boolean) => {
    return{
        type: SET_TAREA_VENCIMIENTO,
        payload: vencimiento
    }
}

export const set_tarea_cantidad = (cantidad: number) => {
    return{
        type: SET_TAREA_CANTIDAD,
        payload: cantidad
    }
}

export const set_tarea_tiempo = (tiempo: string) => {
    return{
        type: SET_TAREA_TIEMPO,
        payload: tiempo
    }
}

export const set_tarea_secuencia = (secunecia: string) => {
    return{
        type: SET_TAREA_SECUENCIA,
        payload: secunecia
    }
}

export const set_tarea_categoria = (categoria: string) => {
    return{
        type: SET_TAREA_CATEGORIA,
        payload: categoria
    }
}

export const set_tarea_notificacion = (notificacion: boolean) => {
    return{
        type: SET_TAREA_NOTIFICACION,
        payload: notificacion
    }
}

export const set_tarea_autoasignacion = (autoasignacion: boolean) => {
    return{
        type: SET_TAREA_AUTOASIGNACION,
        payload: autoasignacion
    }
}

export const set_tarea_tipoasignacion = (tipoasignacion: boolean) => {
    return{
        type: SET_TAREA_TIPOASIGNACION,
        payload: tipoasignacion
    }
}

export const set_tarea_segmentacion = (segmentacion: []) => {
    return{
        type: SET_TAREA_SEGMENTACION,
        payload: segmentacion
    }
}

export const set_tarea_segmentacionType = (segmentacionType: boolean) => {
    return{
        type: SET_TAREA_SEGMENTACIONTYPE,
        payload: segmentacionType
    }
}

export const set_tarea_from = (from: "") => {
    return{
        type: SET_TAREA_FROM,
        payload: from
    }
}

export const set_tarea_to = (to: "") => {
    return{
        type: SET_TAREA_TO,
        payload: to
    }
}

export const set_tarea_evaluation_id = (evaluation_id: any) => {
    return{
        type: SET_TAREA_EVALUATION_ID,
        payload: evaluation_id
    }
}

export const set_tarea_attachments = (attachments: []) => {
    return{
        type: SET_TAREA_ATTACHMENTS,
        payload: attachments
    }
}

export const set_tarea_order = (order: any) => {
    return{
        type: SET_TAREA_ORDER,
        payload: order
    }
}