import * as Fnctn from '../../contexts/GenericFunctions'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import notify from 'devextreme/ui/notify';

const GetNamesEmployees = async (dtArrays: any, edit:boolean, evaluation_id:string) => {
    try {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let Employees: any[] = [];

        if (dtArrays.Evaluadores.length > 0) {
            let Objt = {
                cvetra: dtArrays.Evaluadores,
                edit: edit,
                evaluation_id: evaluation_id
            };

            let dtEvaluations: any = await fetch(`${api}/rating/evaluate_employee/true`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(Objt)
            });
            dtEvaluations = await dtEvaluations.json();
            if (dtEvaluations.valid) {
                Employees.push(dtEvaluations.data);
            }
            else throw (dtEvaluations.message);
        }

        if (dtArrays.Evaluados.length > 0) {
            let Objt = {
                cvetra: dtArrays.Evaluados,
                edit: false,
                evaluation_id: evaluation_id
            };

            let dtEvaluations: any = await fetch(`${api}/rating/evaluate_employee/false&${edit}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(Objt)
            });
            dtEvaluations = await dtEvaluations.json();
            if (dtEvaluations.valid) {
                Employees.push(dtEvaluations.data);
            }
            else throw (dtEvaluations.message);
        }
        halcoloaderRemove();
        return Employees;
    }
    catch (error) {
        halcoloaderRemove();
        notify(error, 'error');
    }
}

export { 
    GetNamesEmployees
}