import { Component } from 'react';
import { List } from 'devextreme-react';
import Accordion from 'devextreme-react/accordion';
import * as Fnctn from '../../contexts/GenericFunctions';
import ItemRenderdtList from '../../pages/LibretaTareas/EditarTarea/TaskItems';
import { ItemDragging } from 'devextreme-react/list';
import { set_tarea_id } from '../../reduxStore/LibretaTareas/LB_actions';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';

interface IProps {
}
interface IState {
    Categories: any,
    Tasks: any,
    categoryIndex: any
}
class Categories extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            Categories: [],
            Tasks: [],
            categoryIndex: -1
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = async () => {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();
        const dtresult = await fetch(`${api}/catalogs/categories/?onboarding=false`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        const dtCategories = await dtresult.json();
        const sortedItems = await [...dtCategories.data[0]].sort((a, b) => a.order - b.order);
        this.setState({ Categories: sortedItems });
        halcoloaderRemove();
    }

    ItemRenderAccordion = (item: any) => {
        return (
            <div className='listCategoriesContent'>
                <img src={item.img_path} alt='Imagen de categoría' className='ImgCategories' />
                <label className='lbTitleCategories'>{item.name}</label>
            </div>
        )
    }

    getTasks = async (items: any) => {
        halcoloaderActive();
        this.setState({ Tasks: [] });
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();
        const dtresult = await fetch(`${api}/training/Task`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });

        const dtTasks = await dtresult.json();
        const DataTasks = dtTasks.data.filter((item: any) => item.category_id == items.itemData._id);
        const sortedItems = [...DataTasks].sort((a, b) => a.order - b.order);
        await this.setState({ Tasks: sortedItems });
        halcoloaderRemove();
    }

    nav = async (navigate: any, dispatch: any, idTarea: string) => {
        dispatch(set_tarea_id(idTarea))
        await navigate('/tasks')
    }

    deleteTask = async (taskId: any) => {
        this.setState(prevState => ({
            Tasks: prevState.Tasks.filter((task: any) => task._id !== taskId)
        }));
    }

    onDragStart = (e: any) => {
        e.itemData = this.state.Tasks[e.fromIndex]
    }

    onAdd = async (e: any) => {
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();
        const tasks = this.state.Tasks
        const updatedTasks = [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)];
        for (let i: number = 0; i < updatedTasks.length; i++) {
            updatedTasks[i].order = i
        }

        updatedTasks.forEach(async (element: any) => {
            let Objt = { "order": element.order }

            await fetch(`${api}/training/Task/${element._id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(Objt)
            });
        });
        await this.setState({ Tasks: updatedTasks })
    }

    onRemove = async (e: any) => {
        const tasks = this.state.Tasks
        const updatedTasks = [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)];
        await this.setState({ Tasks: updatedTasks })

    }

    onReorder = async (e: any) => {
        await this.onRemove(e);
        await this.onAdd(e);
    }

    ItemRender = () => {
        return (
            <div>
                <List
                    dataSource={this.state.Tasks}
                    height={400}
                    selectionMode={"none"}
                    displayExpr={"name"}
                    keyExpr={"_id"}
                    itemComponent={(prop: any) => <div><ItemRenderdtList data={prop.data} deleteTask={this.deleteTask} /></div>}
                >
                    <ItemDragging
                    allowReordering={true}
                    onDragStart={(e) => this.onDragStart(e)}
                    onAdd={(e) => this.onAdd(e)}
                    onRemove={(e) => this.onRemove(e)}
                    onReorder={(e) => this.onReorder(e)}
                >
                    </ItemDragging>
                </List>
            </div>
        )
    }

    ChangeIndex = (items:any) => {
        halcoloaderActive();
        this.setState({categoryIndex: items.addedItems.length <= 0 ? -1 : items.addedItems[0].order})
        halcoloaderRemove();
    }

    render() {
        return (
            <div>
                <Accordion
                    selectedIndex={this.state.categoryIndex}
                    className='AccordionCategories'
                    dataSource={this.state.Categories}
                    animationDuration={270}
                    collapsible={true}
                    itemRender={() => this.ItemRender()}
                    itemTitleRender={(e) => this.ItemRenderAccordion(e)}
                    onItemTitleClick={(e) => { this.getTasks(e); }}
                    onSelectionChanged={(e:any) => this.ChangeIndex(e)}
                />
            </div>
        );
    }
}


export default Categories;