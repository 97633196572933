import React, { useState, useEffect } from 'react';
import { TagBox, SelectBox, Button } from 'devextreme-react';
import { ProgressBar } from 'react-bootstrap';
import * as Fnctn from '../../contexts/GenericFunctions'
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { DataGridTypes } from "devextreme-react/data-grid"
import '../PerformanceTrackingReport/PerformanceTrackingReport.scss'
import { viewFile, enumPath } from '../../contexts/FirebaseActions'
import { Tooltip } from 'devextreme-react';

interface PropsSucursales {
    getIds: (e: any, tb: string) => void;
}

const TagBoxSucursales: React.FC<PropsSucursales> = ({ getIds }) => {
    const [sucursales, setSucursales] = useState<any>();

    useEffect(() => {
        getSucursales();
    }, []);

    const getSucursales = async () => {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/catalogs/branches/?cvecia=007`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        dtResult = await dtResult.json();

        setSucursales(dtResult.data);
        let e: any = { value: "result" }
        itemclick(e, "preload", "sucursales", dtResult.data)
        halcoloaderRemove();
    }

    const itemclick = async (e: any, mode: string, tb: string = "sucursales", optionalarray: any[] = []) => {
        if (mode === "single") {
            let arrayIds: any = [];
            await e.component.option('selectedItems').forEach((element: any) => { arrayIds.push(element.code_branch); });
            getIds(arrayIds, tb);
        }
        else if ((mode === "all")) {
            if (e.value) {
                let arrayIds: any = [];
                await sucursales.forEach((element: any) => { arrayIds.push(element.code_branch); });
                getIds(arrayIds, tb);
            }
            else {
                getIds([], tb);
            }
        }
        else if ((mode === "preload")) {
            let arrayIds: any = [];
            await optionalarray.forEach((element: any) => { arrayIds.push(element.code_branch); });
            getIds(arrayIds, tb);
        }
    }

    return (
        <TagBox
            className='tbSucursales'
            dataSource={sucursales}
            label='Sucursales'
            showSelectionControls={true}
            searchEnabled={true}
            multiline={false}
            showMultiTagOnly={false}
            displayExpr={"name"}
            valueExpr={"_id"}
            onItemClick={(e: any) => itemclick(e, "single")}
            onSelectAllValueChanged={(e: any) => itemclick(e, "all")}
        ></TagBox>
    );
};

interface PropsAreas {
    getIds: (e: any, tb: string) => void;
}

const TagBoxAreas: React.FC<PropsAreas> = ({ getIds }) => {
    const [areas, setAreas] = useState<any>();

    useEffect(() => {
        getAreas();
    }, []);

    const getAreas = async () => {
        halcoloaderActive();
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/catalogs/areas`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        dtResult = await dtResult.json();

        setAreas(dtResult.data);
        let e: any = { value: "result" }
        itemclick(e, "preload", "areas", dtResult.data)
        halcoloaderRemove();
    }

    const itemclick = async (e: any, mode: string, tb: string = "areas", optionalarray: any[] = []) => {
        if (mode === "single") {
            let arrayIds: any = [];
            await e.component.option('selectedItems').forEach((element: any) => { arrayIds.push(element._id); });
            getIds(arrayIds, tb);
        }
        else if ((mode === "all")) {
            if (e.value) {
                let arrayIds: any = [];
                await areas.forEach((element: any) => { arrayIds.push(element._id); });
                getIds(arrayIds, tb);
            }
            else {
                getIds([], tb);
            }
        }
        else if ((mode === "preload")) {
            let arrayIds: any = [];
            await optionalarray.forEach((element: any) => { arrayIds.push(element._id); });
            getIds(arrayIds, tb);
        }
    }


    return (
        <TagBox
            className='tbAreas'
            dataSource={areas}
            label='Areas'
            showSelectionControls={true}
            multiline={false}
            showMultiTagOnly={false}
            displayExpr={"name"}
            valueExpr={"_id"}
            onItemClick={(e: any) => itemclick(e, "single")}
            onSelectAllValueChanged={(e: any) => itemclick(e, "all")}
        ></TagBox>
    );
};

const CellRenderProgressBar = (data: DataGridTypes.ColumnCellTemplateData, type: string) => {
    if (type === "Porcent") {
        return (<div>{data.text}</div>)
    }
    else if (type === "Progress") {
        let valores = data.displayValue,
            porcentProgress: number = 0,
            progressColor: string = "",
            newLabel: string = "";

        if (!Fnctn.NullUndefinedEmpty(valores)) {

            valores = valores.split("/")
            for (let i: number = 0; i < valores.length; i++) {
                valores[i] = parseInt(valores[i]);
            };
            porcentProgress = ((valores[0] / valores[1]) * 100);

            if (porcentProgress > 0 && porcentProgress <= 99) {
                progressColor = 'info';
                newLabel = data.value;
            }
            else if (porcentProgress === 100) {
                progressColor = 'success';
                newLabel = "Compleatado";
            }
            return (
                <ProgressBar
                    now={valores[0]}
                    max={valores[1]}
                    label={newLabel}
                    animated={true}
                    variant={progressColor}
                    style={{ fontSize: "10px" }}
                ></ProgressBar>
            )
        }
    }
};

const RenderHeader = (headerInfo: any) => {
    return (
        <div style={{ display: "flex", whiteSpace: "normal" }}>
            {headerInfo.column.caption}
        </div>
    );
};

const CellRenderDtForm = (cellInfo: any) => {
    const keys: any = Object.keys(cellInfo.data);
    const lastKey = keys[keys.length - 2];
    const param = cellInfo.data[lastKey]

    if (cellInfo.column.customProperty === "file") {
        if (!Fnctn.NullUndefinedEmpty(param)) return (<Button className='btnViewFile' style={{ display: "flex", whiteSpace: "normal" }} text='VER DOCUMENTO' onClick={() => viewFile(enumPath.ARCHIVOS_EVALUACIONES, param)}></Button>);
        else return (<div></div>)
    }
    else {
        return (<div style={{ display: "flex", whiteSpace: "normal" }}>{cellInfo.displayValue.option}</div>);
    }
};

const CreeateHeadersGeneralForms = (data: any) => {
    const propiedadesQuestion: any = [];
    const headerlabels: any = [];

    for (const propiedad in data[0].questions) {
        const matchQuestion = propiedad.match(/question_(\d+)_?.*$/);

        if (matchQuestion) {
            propiedadesQuestion.push({
                nombre: propiedad,
                numero: parseInt(matchQuestion[1]),
                valor: data[0].questions[propiedad],
                dataField: `answer_${matchQuestion[1]}`,
                ResponseType: data[0].questions[`response_type_${matchQuestion[1]}`],
                visible: false
            });
        }
    }

    propiedadesQuestion.sort((a: any, b: any) => a.numero - b.numero);

    propiedadesQuestion.forEach((element: any) => {
        const matchCategoria = element.nombre.match(/question_(\d+)_?.*$/);

        if (matchCategoria) {
            headerlabels.push(
                {
                    key: element.nombre,
                    dataField: element.dataField,
                    caption: element.valor,
                    visible: true,
                    ResponseType: element.ResponseType
                }
            );
        }
    });
    return headerlabels
}

interface PropsEvaluations {
    getIds: (e: any, tb: any) => void;
    getEvaluationName: (e: any, tb: any) => void;
}

const TextBoxEvaluations: React.FC<PropsEvaluations> = ({ getIds, getEvaluationName }) => {
    const [evaluations, setEvaluations] = useState<any>();

    useEffect(() => {
        getEvaluations();
    }, []);

    const getEvaluations = async () => {
        halcoloaderActive();

        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let dtResult: any = await fetch(`${api}/rating/performance_report_list_evaluation`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });

        dtResult = await dtResult.json();
        dtResult = [...dtResult.data].sort((a, b) => a.order - b.order);
        setEvaluations(dtResult);

        halcoloaderRemove();
    }

    const itemclick = async (e: any, tb: string = "Evaluaciones") => {
        getIds([e.itemData._id, e.itemData.form_id], "Evaluaciones");
        getEvaluationName(e.itemData.name, tb);
    }
    
    const RenderListElement = (e: any) => {
        let classString = `dx-icon dx-icon-info IconEvaluacion`;
        let id = "IdEvaluation_" + e._id;
        let icon = <div>
            <i className={classString} id={id}></i>
            <Tooltip
                target={`#${id}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
            >
                <label className='textDescription'>{e.description}</label>
            </Tooltip>
        </div>

        return (
            <div style={{display: "flex"}}>
                <label className='tgTitle'>{e.name}</label>{icon}
            </div>
        )
    }

    return (
        <SelectBox
            className='tbEvaluaciones'
            dataSource={evaluations}
            label='Evaluaciones'
            displayExpr={"name"}
            valueExpr={"form_id"}
            width={"30%"}
            onItemClick={(e: any) => itemclick(e, "Evaluaciones")}
            itemRender={RenderListElement}
        ></SelectBox>
    );
};

export {
    TagBoxSucursales
    , TagBoxAreas
    , CellRenderProgressBar
    , RenderHeader
    , CreeateHeadersGeneralForms
    , TextBoxEvaluations
    , CellRenderDtForm
}