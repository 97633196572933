import {useState}from 'react';
import DropDownButton from 'devextreme-react/drop-down-button';
import '../../LibretaTareas/LibretaTareas.scss'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as lbActions from '../../../reduxStore/LibretaTareas/LB_actions';
import * as nfActions from '../../../reduxStore/NewForms/NF_actions';
import * as Fnctn from '../../../contexts/GenericFunctions';
import { Popup, Tooltip } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import NewForm from '../NewForm/NewForm'
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader';

interface IProps {
    prop: any,
    deleteTask: (taskId:string) => void
}

function TaskItem({ prop, deleteTask  }: IProps){
    const [visiblePopUp, setvisiblePopUp] = useState<boolean>(false);
    const [ItemsMenu, setItemsMenu] = useState<any>([
        { id: 1, name: 'Editar tarea', icon: 'edit' },
        { id: 2, name: 'Eliminar tarea', icon: 'close' }
    ]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const nav = async (navigate: any, dispatch: any, objTask: any) => {
        halcoloaderActive();
        if(Fnctn.NullUndefinedEmpty(objTask.evaluation_id)) objTask.evaluation_id = null;

        dispatch(lbActions.set_tarea_id(objTask._id));
        dispatch(lbActions.set_tarea_titulo(objTask.title));
        dispatch(lbActions.set_tarea_img(objTask.icon_path));
        dispatch(lbActions.set_tarea_imgname(objTask.icon_name));
        dispatch(lbActions.set_tarea_contenido(objTask.content));
        dispatch(lbActions.set_tarea_vencimiento(objTask.has_expiration));
        dispatch(lbActions.set_tarea_cantidad(objTask.expiration_amount));
        dispatch(lbActions.set_tarea_tiempo(objTask.expiration_time));
        dispatch(lbActions.set_tarea_secuencia(objTask.expiration_sequence));
        dispatch(lbActions.set_tarea_categoria(objTask.category_id));
        dispatch(lbActions.set_tarea_notificacion(objTask.send_notification));
        dispatch(lbActions.set_tarea_autoasignacion(objTask.autoAssign));
        dispatch(lbActions.set_tarea_tipoasignacion(objTask.type_assignment));
        dispatch(lbActions.set_tarea_segmentacion(objTask.segmentation));
        dispatch(lbActions.set_tarea_segmentacionType(objTask.segmentation_type));
        dispatch(lbActions.set_tarea_from(objTask.from));
        dispatch(lbActions.set_tarea_to(objTask.to));
        dispatch(lbActions.set_tarea_evaluation_id(objTask.evaluation_id));
        dispatch(lbActions.set_tarea_attachments(objTask.attachments));
        dispatch(lbActions.set_tarea_order(objTask.order));
        await navigate('/tasks');
        halcoloaderRemove();
    }

    const ViewForm = async (navigate:any, dispatch: any, objTask: any) => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let dtTask = await fetch(`${api}/training/evaluations/${objTask.evaluation_id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        
        let dtForm = await dtTask.json();
        let questions:any = dtForm.data[0].questionnaire[0].questions;
        dispatch(nfActions.set_form_id(dtForm.data[0]._id));
        dispatch(nfActions.set_form_name(objTask.title));
        dispatch(nfActions.set_form_returnview("/LibretaTareas"));
        dispatch(nfActions.set_form_questions(questions));
        dispatch(nfActions.set_type_form("F"));
        await navigate('/Cuestionario');
        halcoloaderRemove();
    }

    const addFormTask = () => {
        setvisiblePopUp(true);
    }

    

    const onItemClick = async (opcion: number, id_task: string) => {
        switch (opcion) {
            case 1:
                nav(navigate, dispatch, prop);
                break
            case 2: 
                DeleteTask(id_task);
                break
            case 3:
                addFormTask();
                
                break
            case 4:
                ViewForm(navigate, dispatch, prop);
                break
        }
    };
    
    const OpcionesRender = (e:any) => {
        const icon = `dx-icon dx-icon-${e.icon}`;
        return (
            <div style={{width:"0rem", height:"0rem"}}>
                <i className={icon} style={{fontSize:"20px"}}></i>
                <label style={{margin: "auto auto auto 1rem"}}>{e.name}</label>
            </div>
        );
    }

    const EditOpiones = () => {
        if (Fnctn.NullUndefinedEmpty(prop.evaluation_id)){
            setItemsMenu((prevstatus:any) => [...prevstatus, {id: 3, name: 'Asociar cuestionario', icon: 'ordersbox'}]);
        }
        else{
            setItemsMenu((prevstatus:any) => [...prevstatus, {id: 4, name: 'Ver cuestionario', icon: 'taskhelpneeded'}]);
        }
    }

    const handlePopupHidden = () => {
        setvisiblePopUp(false);
    }

    const renderPopup = () => {
        let PropiedadesForm = {
            _idTarea: prop._id
        }
        return <NewForm props={PropiedadesForm} ></NewForm>
    }

    const DeleteTask = async (id_task: string) => {
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let Objt = {
            "active": false
        }
        await fetch(`${api}/training/task/${id_task}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        await deleteTask(id_task);


    }


    const CuestionarioAsociado = () => {
        let icon:any = null;

        if (!Fnctn.NullUndefinedEmpty(prop.evaluation_id)){
            let classString = `dx-icon dx-icon-taskcomplete IconFormularioAsociado`;
            let id = "IdTask_" + prop._id;
            icon = <div>
                <i className={classString} id={id}></i>
                <Tooltip
                    target={`#${id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={false}
                >
                    <label>Cuestionario Asociado</label>
                </Tooltip>
                </div>
        }
        return (icon);
    }
    return (
        <div>
            <div className='listCategoriesContent'>
                <img src={prop.icon_path} alt='Imagen de categoría' className='ImgCategories' />
                <label className='lbTitleCategories'>{prop.title}</label>
                {CuestionarioAsociado()}
                <DropDownButton
                    className='menuTasks'
                    splitButton={false}
                    useSelectMode={false}
                    icon="overflow"
                    showArrowIcon={false}
                    items={ItemsMenu}
                    displayExpr="name"
                    keyExpr="id"
                    dropDownOptions={{ width: "15rem" }}
                    onItemClick={(e) => onItemClick(e.itemData.id, prop._id)}
                    itemRender={(e) => OpcionesRender(e)}
                    onInitialized={() => EditOpiones()}
                />
            </div>
            <Popup
                width={"40rem"}
                height={"15rem"}
                showTitle={false}
                dragEnabled={false}
                hideOnOutsideClick={true}
                visible={visiblePopUp}
                onHiding={handlePopupHidden}
                contentRender={renderPopup}
                showCloseButton={false}
                resizeEnabled={true}
            />
        </div>
    );
}
export default TaskItem;