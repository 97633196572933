export const SET_CATEGORIA_ID = "SET_CATEGORIA_ID";
export const SET_CATEGORIA_IMG = "SET_CATEGORIA_IMG"
export const SET_CATEGORIA_NOMBRE = "SET_CATEGORIA_NOMBRE"
export const SET_TAREA_SELECCIONADA = "SET_TAREA_SELECCIONADA"
export const SET_NOMBRE_TAREA_SELECIONADA = "SET_NOMBRE_TAREA_SELECIONADA"
export const SET_ID_EVALUACION = "SET_ID_EVALUACION"
export const SET_ID_EVALUACION_FORMULARIO = "SET_ID_EVALUACION_FORMULARIO"
export const SET_ESTATUS_TAREA = "SET_ESTATUS_TAREA"
export const SET_ID_SEGMENTACION = "SET_ID_SEGMENTACION"
export const SET_RETURN_VIEW_EVALUATIONS = "SET_RETURN_VIEW_EVALUATIONS"
export const SET_TYPE_FORM = "SET_TYPE_FORM"
export const SET_WAS_EVALUATED = "SET_WAS_EVALUATED"
export const SET_NAME_EVALUATED = "SET_NAME_EVALUATED"

export const set_categoria_id = (id: string) => {
    return{
        type: SET_CATEGORIA_ID,
        payload: id
    }
}

export const set_categoria_img = (img: string) => {
    return{
        type: SET_CATEGORIA_IMG,
        payload: img
    }
}

export const set_categoria_nombre = (nombre: string) => {
    return {
        type: SET_CATEGORIA_NOMBRE,
        payload: nombre
    }
}

export const set_tarea_seleccionada = (id: string) => {
    return {
        type: SET_TAREA_SELECCIONADA,
        payload: id
    }
}

export const set_nombre_tarea_seleccionada = (nombre: string) => {
    return {
        type: SET_NOMBRE_TAREA_SELECIONADA,
        payload: nombre
    }
}

export const set_id_Evaluacion = (id: string) => {
    return {
        type: SET_ID_EVALUACION,
        payload: id
    }
}

export const set_id_Formulario = (id: string) => {
    return {
        type: SET_ID_EVALUACION_FORMULARIO,
        payload: id
    }
}

export const set_estatus_tarea = (estatus: string) => {
    return {
        type: SET_ESTATUS_TAREA,
        payload: estatus
    }
}

export const set_id_segmentacion = (id: string) => {
    return {
        type: SET_ID_SEGMENTACION,
        payload: id
    }
}

export const set_return_view_evaluations = (view_evaluations: boolean) => {
    return {
        type: SET_RETURN_VIEW_EVALUATIONS,
        payload: view_evaluations
    }
}

export const set_type_form = (type_form: string) => {
    return {
        type: SET_TYPE_FORM,
        payload: type_form
    }
}

export const set_was_evaluated = (was_evaluated: string) => {
    return {
        type: SET_WAS_EVALUATED,
        payload: was_evaluated
    }
}

export const set_name_evaluated = (name_evaluated: string) => {
    return {
        type: SET_NAME_EVALUATED,
        payload: name_evaluated
    }
}
