import React, { Component } from 'react'
import { RootState } from '../../../reduxStore/reducers'
import { connect } from 'react-redux'
import { ActualizarEstatusTarea, ObtenerContenidoTarea } from '../../../api/DataOnBoarding'
import './view_DetalleTarea.scss'
import { Link, Navigate } from 'react-router-dom'
import { Button, Gallery, LoadIndicator, Popup } from 'devextreme-react'
import * as OB_actions from '../../../reduxStore/OverBoard/OB_actions'
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader'
import { OpenFile } from '../../../contexts/GenericFunctions'


interface IProps{
    IdTareaSeleccionada:string;
    IdCategoriaSeleccionada:string;
    NombreTareaSeleccionada:string;
    EstatusTarea:string;
    IdSegmentacion: string;
    set_id_Evaluacion: any;
    set_return_View: any;
    set_type_Form: any;
}

interface IState{
    ContenidoTarea?: any
    IdCuestionario?: any
    Loading?: any
    NavAtras?: any
    Show_FinTarea_mensage?: any
    ListaVideos?: Array<any>
}

const divVideo = (dt:any) => {
    let itemGallery: any;

    if (dt.extension.includes("mp4")) {
        itemGallery = <p style={{ width: 'auto' }}>
            <video controls id={dt.url} className='VideoTarea'>
                <source className='Source' src={dt.url + `#t=0,7`} />
            </video>
        </p>
    }
    else{
        itemGallery =
                <div className='cardFile'>
                    <div className='cardFile-content' id={dt.url} onClick={() => OpenFile(dt.url)}>
                        {dt.archive_name}
                        <embed src={dt.url} style={{width: '99%', height: '21rem'}}></embed>
                    </div>
                </div>
    }

    return (
    <div>
        {itemGallery}
    </div>
    )
}

class view_DetalleTarea extends Component<IProps, IState>{
    constructor(props: IProps){
        super(props)
        this.state = {
            ContenidoTarea: ''
            , IdCuestionario: ''
            , Loading: false
            , NavAtras: false
            , Show_FinTarea_mensage: false
            , ListaVideos: []
        }
    }

    componentDidMount(): void {
        this.ObtenerTarea()
    }

    ObtenerTarea = async () => {
        halcoloaderActive()

        if (this.props.EstatusTarea === 'Not Started'){
            await ActualizarEstatusTarea(this.props.IdSegmentacion, 'In Progress')
        }

        let res = await ObtenerContenidoTarea(this.props.IdTareaSeleccionada)
        let tarea = res.data

        const ContenidoTarea = tarea[0].content === '' ? `<div></div>` : tarea[0].content

        let videos: any = []

        tarea[0].attachments?.map((v: any) => {
            videos.push(v)
        })

        await this.props.set_id_Evaluacion(tarea[0].evaluation_id)
        await this.props.set_return_View(false)
        await this.props.set_type_Form("F")
        this.setState({ ContenidoTarea: ContenidoTarea, ListaVideos: videos, IdCuestionario: tarea[0].evaluation_id, Loading: false })

        halcoloaderRemove()
    }

    FinalizarTarea = async () => {
        await ActualizarEstatusTarea(this.props.IdSegmentacion, 'Completed')
        this.setState({ NavAtras: true })
    }

    Cambio = async (e: any) => {
        let video = document.getElementById(e.removedItems[0]) as HTMLMediaElement
        video?.pause();
    }

    render(){
        return (
            <div className='divPrincipal'>
                <Popup
                    visible={this.state.Show_FinTarea_mensage}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_FinTarea_mensage: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>¿Finalizar tarea?</label>
                        <p style={{ fontSize: 15, marginTop: 20, marginBottom: 20 }}>La tarea se dará por finalizada</p>
                        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            <Button className='botonAceptar' onClick={() => this.FinalizarTarea() }><label style={{ fontWeight: 'bold', fontSize: 13 }}>Aceptar</label></Button>
                        </div>
                    </div>
                </Popup>

                <div className='buttonAtras2' style={{ display: 'flex', flexDirection: 'column' }} >
                    <div style={{ width: '70%', alignSelf: 'center' }}>
                        <Link to={'/DetalleCategoria'}>
                            <Button className='btnAtras' icon="back" text="Atras"></Button>
                        </Link>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ width: '70%', alignSelf: 'center', justifyContent: 'center' }}>{this.props.NombreTareaSeleccionada}</h4>
                </div>
                {
                    this.state.Loading
                    ?
                        <div className='divContenido'>
                            <LoadIndicator id="large-indicator" height={60} width={60} />
                        </div>
                    :
                        <div className='divContenido'>
                            <div className='contenidoTarea' dangerouslySetInnerHTML={{ __html: this.state.ContenidoTarea }} />

                            <Gallery
                                id='VideosGaleria'
                                dataSource={this.state.ListaVideos}
                                height={"15hv"}
                                width={"60hv"}
                                slideshowDelay={0}
                                loop={false}
                                showNavButtons={true}
                                showIndicator={true}
                                itemRender={(e) => divVideo(e)}
                                onSelectionChanged={(e) => this.Cambio(e)}
                            />

                        </div>
                }
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width:'70%', alignSelf: 'center' }}>
                    {
                        this.props.EstatusTarea === "Completed"
                        ?
                            <div></div>
                        :
                            this.state.IdCuestionario != null ?
                            <Link to={'/EvaluacionTarea'}>
                                <Button className='buttonCompletarTarea'>
                                    Completar cuestionario
                                </Button>
                            </Link>
                            :
                            <Button className='buttonCompletarTarea' onClick={() => this.setState({ Show_FinTarea_mensage: true })}>
                                Finalizar
                            </Button>
                    }
                    </div>
                </div>
                {
                    this.state.NavAtras && <Navigate to='/DetalleCategoria'/> 
                }
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        IdTareaSeleccionada: state.OverBoard.IdTareaSeleccionada,
        IdCategoriaSeleccionada: state.OverBoard.IdCategoriaSeleccionada,
        NombreTareaSeleccionada: state.OverBoard.NombreTareaSeleccionada,
        EstatusTarea: state.OverBoard.EstatusTarea,
        IdSegmentacion: state.OverBoard.IdSegmentacion
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        set_id_Evaluacion: (id: string) => dispatch(OB_actions.set_id_Evaluacion(id)),
        set_return_View: (view: boolean) => dispatch(OB_actions.set_return_view_evaluations(view)),
        set_type_Form: (type_Form: string) => dispatch(OB_actions.set_type_form(type_Form))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_DetalleTarea)
