import * as Actions  from './EV_actions'

export interface EV_State {
    Id_Evaluation: string
    , Title: string
    , Description: string
    , Evaluators: any[]
    , Evaluateds: any[]
    , Form_Id: any
    , Img_name: string
    , Img_path: string
    , Order: number
    , Deploye: boolean
}

const initialState: EV_State = {
    Id_Evaluation: ""
    , Title: ""
    , Description: ""
    , Evaluators: []
    , Evaluateds: []
    , Form_Id: null
    , Img_name: ""
    , Img_path: ""
    , Order: -1
    , Deploye: false
}

const EV_reducer = (state = initialState, action: any ) =>{
    switch(action.type){
        case Actions.SET_EVALUATION_ID: return {...state, Id_Evaluation: action.payload}
        case Actions.SET_EVALUATION_TITLE: return {...state, Title: action.payload}
        case Actions.SET_EVALUATION_DESCRIPTION: return {...state, Description: action.payload}
        case Actions.SET_EVALUATION_EVALUATORS: return {...state, Evaluators: action.payload}
        case Actions.SET_EVALUATION_EVALUATEDS: return {...state, Evaluateds: action.payload}
        case Actions.SET_EVALUATION_FORM_ID: return {...state, Form_Id: action.payload}
        case Actions.SET_EVALUATION_IMG_NAME: return {...state, Img_name: action.payload}
        case Actions.SET_EVALUATION_IMG_PATH: return {...state, Img_path: action.payload}
        case Actions.SET_EVALUATION_ORDER: return {...state, Order: action.payload}
        case Actions.SET_EVALUATION_DEPLOYE: return {...state, Deploye: action.payload}
        default:
            return state
    }
}

export default EV_reducer