//approutes

import  * as Pages  from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: Pages.TasksPage
    },
    {
        path: '/home',
        element: Pages.HomePage
    },
    {
        path: '/LibretaTareas',
        element: Pages.LibretaTareasPage
    },
    {
        path: '/NuevaCategoria',
        element: Pages.NuevaCategoria
    },
    {
        path: '/Cuestionario',
        element: Pages.CestionarioPage
    },
    {
        path: '/OverBoard',
        element: Pages.Home_OverView
    },
    {
        path: '/DetalleCategoria',
        element: Pages.view_DetalleCategoria
    },
    {
        path: '/DetalleTarea',
        element: Pages.view_DetalleTarea
    },
    {
        path: '/EvaluacionTarea',
        element: Pages.view_EvaluacionTarea
    },
    {
        path: '/ReportTasks',
        element: Pages.ReportTasks
    },
    {
        path: '/ReportGeneralEvaluations',
        element: Pages.view_EvaluacionesGeneral
    },
    {
        path: '/Evaluations',
        element: Pages.view_Evaluaciones
    },
    {
        path: '/EditEvaluation',
        element: Pages.view_EditEvaluacion
    },
    {
        path: '/NewEvaluation',
        element: Pages.view_NewEvaluation
    },
    {
        path: '/EvaluationPg',
        element: Pages.view_EvaluationPg
    },
    {
        path: '/PerformanceTrackingReport',
        element: Pages.view_PerformanceTrackingReport
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
    
});



