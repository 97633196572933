import { title } from 'process'
import * as Actions  from './NEV_actions'

export interface NEV_State {
    Id_Evaluation: string
    , Title: string
    , Description: string
    , Evaluators: any[]
    , Evaluateds: any[]
    , Img: string
}

const initialState: NEV_State = {
    Id_Evaluation: ""
    , Title: ""
    , Description: ""
    , Evaluators: []
    , Evaluateds: []
    , Img: ""
}

const NEV_reducer = (state = initialState, action: any ) =>{
    switch(action.type){
        case Actions.SET_EVALUATION_ID: return {...state, Id_Evaluation: action.payload}
        case Actions.SET_EVALUATION_TITLE: return {...state, Title: action.payload}
        case Actions.SET_EVALUATION_DESCRIPTION: return {...state, Description: action.payload}
        case Actions.SET_EVALUATION_EVALUATORS: return {...state, Evaluators: action.payload}
        case Actions.SET_EVALUATION_EVALUATEDS: return {...state, Evaluateds: action.payload}
        case Actions.SET_EVALUATION_IMG: return {...state, Img: action.payload}
        default:
            return state
    }
}

export default NEV_reducer