import Ob_reducer, { Ob_State } from '../OverBoard/OB_reducer' // Onboarding
import LB_reducer, { LB_State, } from '../LibretaTareas/LB_reducer' // Libreta Tareas
import NF_reducer, { NF_State, } from '../NewForms/NF_reducer' // Nuevo Formulario
import EV_reducer, { EV_State, } from '../Evaluations/EV_reducer' // Evaluacione
import NEV_reducer, { NEV_State, } from '../Evaluations/NewEvaluations/NEV_reducer' // Nueva Evaluación
import { combineReducers } from "redux";

export interface RootState {
    OverBoard: Ob_State
    , LibretaTareas: LB_State
    , NewForm: NF_State
    , Evaluations: EV_State
    , NewEvaluations: NEV_State
}

const rootReducers = combineReducers({
    OverBoard: Ob_reducer
    , LibretaTareas: LB_reducer
    , NewForm: NF_reducer
    , Evaluations: EV_reducer
    , NewEvaluations: NEV_reducer
});

export default rootReducers
