import { useEffect, useState } from 'react';
import * as Fnctn from '../../contexts/GenericFunctions';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { Button } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as OB_actions from '../../reduxStore/OverBoard/OB_actions'

interface IProps {
    status: any
}

const Get_Evaluations_User = ({ status }: IProps) => {
    const [arrayDiv, setArrayDiv] = useState<any>([]);

    useEffect(() => {
        halcoloaderActive();
        GetData_EvaluationsUser();
    }, []);

    const nav = async (navigate: any, dispatch: any, ItemProps: any) => {
        dispatch(OB_actions.set_id_Evaluacion(ItemProps.form_id));
        dispatch(OB_actions.set_id_Formulario(ItemProps._id));
        dispatch(OB_actions.set_return_view_evaluations(true));
        dispatch(OB_actions.set_type_form("E"));
        dispatch(OB_actions.set_was_evaluated(ItemProps.evaluated_cvetra));
        dispatch(OB_actions.set_name_evaluated(ItemProps.evaluated_name));
        dispatch(OB_actions.set_nombre_tarea_seleccionada(ItemProps.evaluation_name));
        navigate('/EvaluacionTarea');
    };

    const TaskItem = (props: any) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();

        return (
            <Button onClick={() => { if (!status.includes("Completed")) nav(navigate, dispatch, props) }} className={'btnTarea'}>
                <div className='IconoTarea'>
                    <img className='ImgIcono' src={Fnctn.NullUndefinedEmpty(props.img_path) ? 'https://firebasestorage.googleapis.com/v0/b/halconet-27dd9.appspot.com/o/HalcoHumand%2FICONOS_TAREAS%2Flogo-halconet_sinletras.svg?alt=media&token=cd51f543-4a9b-4685-85f7-50f9976b4146' : props.img_path} alt='Img' />
                </div>
                <div className={'TareaItem'}>
                    <div style={{ display:"flex", justifyContent:"center", margin: "20px 0px 5px 25px" }}>
                        <label style={{ fontWeight: 'bold', fontSize: "16px" }}>Evaluación: </label>
                        <label className="task-item-text">{props.evaluation_name}</label>
                    </div>
                    <div style={{ display:"flex", justifyContent:"center", margin: "0px 0px 5px 25px" }}>
                        <label style={{ fontWeight: 'bold', fontSize: "16px" }}>Se evalua a: </label>
                        <label className="task-item-text">{props.evaluated_name}</label>
                    </div>
                    <div style={{ display:"flex", justifyContent:"center", margin: "0px 0px 5px 25px" }}>
                        <label style={{ fontWeight: 'bold', fontSize: "16px" }}>Fecha de asignación: </label>
                        <label className="task-item-info">{`${props.create_date.split('T')[0]}`}</label>
                    </div>
                </div>
                <div className={'TareaItem'}>
                <div style={{ display:"flex", margin: "0px 0px 5px 25px" }}>
                        <label style={{ fontWeight: 'bold', fontSize:"14px" }}>Descripción: </label>
                        <label  style={{whiteSpace: "normal", "textAlign": "start", "margin": "0px 0px 0px 15px"}}>{props.evaluation_description}</label>
                    </div>
                </div>
            </Button>
        );
    };

    const GetData_EvaluationsUser = async () => {
        try {
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();

            const Objt = {
                cvetra: data.cvetra,
                status: status
            };
            const response = await fetch(`${api}/rating/get_evaluations_user`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(Objt)
            });
            const dtResult = await response.json();

            if (dtResult.valid) {
                const taskItems = dtResult.data.map((item: any) => (
                    <TaskItem key={item.id} {...item} />
                ));
                setArrayDiv(taskItems);
            } else {
                throw new Error(dtResult.message);
            }
            halcoloaderRemove();
        }
        catch (error) {
            halcoloaderRemove();
        }
    };

    return (
        <div>
            {arrayDiv.length > 0 ? arrayDiv : <div>No hay evaluaciones disponibles.</div>}
        </div>
    );
};

export default Get_Evaluations_User;
