import React, { Component } from 'react';
import { List } from 'devextreme-react';
import * as Fnctn from '../../contexts/GenericFunctions';
import DropDownButton from 'devextreme-react/drop-down-button';
import { ItemDragging } from 'devextreme-react/list';
import { TextBox, Button, Popup } from 'devextreme-react';
import '../NuevaCategoría/NuevaCategoria.scss'
import notify from 'devextreme/ui/notify';
import {halcoloaderActive, halcoloaderRemove} from '../../contexts/HalcoLoader'
import * as FireBase from '../../contexts/FirebaseActions'

interface IProps {
}
interface IState {
    Categories: any,
    Tasks: any,
    Loading: any,
    visibleEditCategoria: boolean,
    valueTitulo: string,
    newTitulo: string,
    idTitulo: string,
    fotoCategoria: any,
    nombreFotoCategoria: any,
    inputRef: any,
    file: any,
    changefile: boolean,
    Show_confirm_DeleteCategory: boolean
}
class EditarCategorias extends Component<IProps, IState> {
    api = Fnctn.GetAPI();
    data = Fnctn.getCookie();
    constructor(props: IProps) {
        super(props);
        this.state = {
            Categories: undefined,
            Tasks: [],
            Loading: false,
            visibleEditCategoria: false,
            valueTitulo: "",
            idTitulo: "",
            newTitulo: "",
            fotoCategoria: "",
            nombreFotoCategoria: "",
            inputRef: React.createRef(),
            file: null,
            changefile: false,
            Show_confirm_DeleteCategory: false
        }
    }

    ItemsMenu = [
        { id: 1, name: 'Editar tarea', icon: 'edit' },
        { id: 2, name: 'Eliminar tarea', icon: 'close' }
    ];

    componentDidMount() {
        this.getCategories();
    }

    getCategories = async () => {
        halcoloaderActive();
        const dtresult = await fetch(`${this.api}/catalogs/categories/?onboarding=false`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.data.token}`
            }
        });
        const dtCategories = await dtresult.json();
        const sortedItems = [...dtCategories.data[0]].sort((a, b) => a.order - b.order);

        this.setState({ Categories: sortedItems });
        halcoloaderRemove();
    }

    ItemRenderAccordion = (item: any) => {
        return (
            <div className='listCategoriesContent'>
                <img src={item.img_path} className='ImgCategories' alt='img' />
                <label className='lbTitleCategories'>{item.name}</label>
            </div>
        )
    }

    getTasks = async (items: any) => {
        halcoloaderActive();
        this.setState({ Tasks: [] });

        const dtresult = await fetch(`${this.api}/training/Task`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.data.token}`
            }
        });
        const dtTasks = await dtresult.json();
        const DataTasks = dtTasks.data.filter((item: any) => item.category_id === items.itemData._id);
        this.setState({ Tasks: DataTasks });
        halcoloaderRemove();
    }

    onItemClick = (opcion: string, id_task: string) => {
    };

    OpcionesRender = (e: any) => {
        const icon = `dx-icon dx-icon-${e.icon}`;
        return (
            <div style={{ width: "0rem", height: "0rem" }}>
                <i className={icon} style={{ fontSize: "20px" }}></i>
                <label style={{ margin: "auto auto auto 1rem" }}>{e.name}</label>
            </div>
        )
    }


    itemRenderdtList = (item: any) => {
        return (
            <div className='listCategoriesContent'>
                <img src={item.icon_path} className='ImgCategories' alt='Img'/>
                <label className='lbTitleCategories'>{item.title}</label>
                <DropDownButton
                    className='menuTasks'
                    splitButton={false}
                    useSelectMode={false}
                    icon="overflow"
                    text='Opciones'
                    showArrowIcon={false}
                    items={this.ItemsMenu}
                    displayExpr="name"
                    keyExpr="id"
                    dropDownOptions={{ width: "10rem" }}
                    onItemClick={(e) => this.onItemClick(e.itemData.id, item._id)}
                    itemRender={this.OpcionesRender}
                />
            </div>
        )
    }

    ItemRender = () => {
        return (
            <div>
                <List
                    dataSource={this.state.Tasks}
                    height={400}
                    selectionMode={"none"}
                    displayExpr={"name"}
                    keyExpr={"_id"}
                    itemRender={this.itemRenderdtList}
                ></List>
            </div>
        )
    }

    deleteCategories = async (e: any) => {
        this.setState({ Show_confirm_DeleteCategory: true });
        const element = e.itemData._id;
        this.setState({ idTitulo: element });
    }

    onDragStart = (e: any) => {
        e.itemData = this.state.Categories[e.fromIndex]
    }

    onAdd = async (e: any) => {
      
        const tasks = this.state.Categories
        const updatedTasks = [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)];

        for (let i: number = 0; i < updatedTasks.length; i++) {
            updatedTasks[i].order = i;
        }

        updatedTasks.forEach(async (element: any) => {
            let Objt = { "order": element.order }

            await fetch(`${this.api}/catalogs/categories/${element._id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.data.token}`
                },
                body: JSON.stringify(Objt)
            });
        });
        await this.setState({ Categories: updatedTasks });
    }

    onRemove = async (e: any) => {
        const tasks = this.state.Categories;
        const updatedTasks = [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)];
        await this.setState({ Categories: updatedTasks });
    }

    onReorder = async (e: any) => {
        await this.onRemove(e);
        await this.onAdd(e);
    }

    itemClick = (e: any) => {
        this.setState({ visibleEditCategoria: true });
        this.setState({ valueTitulo: e.itemData.name });
        this.setState({ newTitulo: e.itemData.name });
        this.setState({ idTitulo: e.itemData._id });
        this.setState({ fotoCategoria: e.itemData.img_path });
        this.setState({ nombreFotoCategoria: e.itemData.img_name });
    }

    ChangeTiutlo = (e: any) => {
        this.setState({ newTitulo: e.value });
        this.setState({ valueTitulo: e.value });
    }

    Cancelar = () => {
        this.setState({ visibleEditCategoria: false });
        this.setState({ valueTitulo: "" });
        this.setState({ idTitulo: "" });
        this.setState({changefile: false});
    }

    Guardar = async () => {
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();


        if (!Fnctn.NullUndefinedEmpty(this.state.newTitulo)) {
            let Objt:any = {
                "name": this.state.newTitulo,
                // "img_path": IconoTarea.PathFireBase 
            }
            
            if (this.state.changefile){ 
                let IconoTarea:any = await FireBase.DeleteFile(this.state.nombreFotoCategoria, FireBase.enumPath.ICONOS_CATEGORIAS);
                IconoTarea = await FireBase.uploadFile(this.state.file, FireBase.enumPath.ICONOS_CATEGORIAS);
                Objt.img_path = IconoTarea.PathFireBase 
                Objt.img_name = IconoTarea.NameFirebase 
            }

            const dtresult = await fetch(`${api}/catalogs/categories/${this.state.idTitulo}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`
                },
                body: JSON.stringify(Objt)
            });
            await dtresult.json();
            this.setState({changefile: false});
            notify("Nombre actualizado correctamente", "success");
        }
        else {
            notify("Debes ingresar un título a la categoría", "error");
        }

        this.Cancelar();
        this.getCategories();
    }

    handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        this.setState({file: file});
        this.setState({changefile: true});
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String: string = reader.result!.toString();
                this.setState({fotoCategoria: base64String});
            };
            reader.readAsDataURL(file);
        }
    };

    handleClick = () => {
        if (this.state.inputRef.current) {
            this.setState({changefile: true});
            this.state.inputRef.current.click();
        }
    };

    ConfirmDeleteCategory = async (id: string) => {
        let api = Fnctn.GetAPI();
        const data = Fnctn.getCookie();

        let Objt = { "active": false }

        const dtresult = await fetch(`${api}/catalogs/categories/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        await dtresult.json();
        this.setState({ Show_confirm_DeleteCategory: false })
    }

    render() {
        return (
            <div className='windowPrincipal'>
                <div className='window1'>
                    <div style={{ margin: '1rem auto 2rem 1rem', color: "#172b4d" }}>
                        <label style={{ fontSize: "1.3rem", margin: "auto auto auto 15px" }}>Categorías</label>
                    </div>
                    <List
                        className='AccordionCategories'
                        dataSource={this.state.Categories}
                        keyExpr={"_id"}
                        displayExpr={"name"}
                        itemRender={(e) => this.ItemRenderAccordion(e)}
                        allowItemDeleting={true}
                        itemDeleteMode={"static"}
                        onItemDeleted={(e) => this.deleteCategories(e)}
                        searchExpr="name"
                        searchEnabled={true}
                        searchMode={'contains'}
                        onItemClick={((e) => this.itemClick(e))}
                    >
                        <ItemDragging
                            allowReordering={true}
                            onDragStart={(e) => this.onDragStart(e)}
                            onAdd={(e) => this.onAdd(e)}
                            onRemove={(e) => this.onRemove(e)}
                            onReorder={(e) => this.onReorder(e)}
                        ></ItemDragging>
                    </List>
                </div>
                {this.state.visibleEditCategoria &&
                    <div className='window2'>
                        <div className={'form-avatar'} style={{ cursor: 'pointer', borderRadius: '10%' }} onClick={this.handleClick}>
                            <img
                                alt={''}
                                src={this.state.fotoCategoria}
                                style={{ width: '100%', height: '100%', objectFit: 'contain', borderColor: "#000" }}
                            />
                            <input
                                ref={this.state.inputRef}
                                type="file"
                                accept="image/*"
                                onChange={this.handleFileUpload}
                                style={{ display: 'none' }}
                            />
                        </div>
                        <TextBox
                            visible={true}
                            className='TituloCategoria'
                            label='Titulo'
                            value={this.state.valueTitulo}
                            onValueChanged={(e) => this.ChangeTiutlo(e)}
                            height={"90%"}
                        />
                        <Button className='btnOpcionesCategorias Guardar' onClick={() => this.Guardar()}>Guardar</Button>
                        <Button className='btnOpcionesCategorias Cancelar' onClick={() => this.Cancelar()}>Cancelar</Button>
                    </div>
                }
                <Popup
                    visible={this.state.Show_confirm_DeleteCategory}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_confirm_DeleteCategory: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>¿Quieres eliminar la catergoria?</label><br />
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button className='btnCancelar' onClick={() => this.setState({ Show_confirm_DeleteCategory: false })}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Cancelar</label></Button>
                            <Button className='btnAceptar' onClick={() => this.ConfirmDeleteCategory(this.state.idTitulo)}><label style={{ fontWeight: 'bold', fontSize: 13 }}>Aceptar</label></Button>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}

export default EditarCategorias;
