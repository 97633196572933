export const SET_EVALUATION_ID = "SET_EVALUATION_ID";
export const SET_EVALUATION_TITLE = "SET_EVALUATION_TITLE";
export const SET_EVALUATION_DESCRIPTION = "SET_EVALUATION_DESCRIPTION";
export const SET_EVALUATION_EVALUATORS = "SET_EVALUATION_EVALUATORS";
export const SET_EVALUATION_EVALUATEDS = "SET_EVALUATION_EVALUATEDS";
export const SET_EVALUATION_IMG = "SET_EVALUATION_IMG";

export const set_evaluation_id = (id: string) => {
    return{
        type: SET_EVALUATION_ID,
        payload: id
    }
}

export const set_evaluation_img = (img: string) => {
    return{
        type: SET_EVALUATION_IMG,
        payload: img
    }
}

export const set_evaluation_title = (title: string) => {
    return{
        type: SET_EVALUATION_TITLE,
        payload: title
    }
}

export const set_evaluation_description = (description: string) => {
    return{
        type: SET_EVALUATION_DESCRIPTION,
        payload: description
    }
}

export const set_evaluation_evaluators = (evaluators: any[]) => {
    return{
        type: SET_EVALUATION_EVALUATORS,
        payload: evaluators
    }
}

export const set_evaluation_evaluateds = (evaluateds: any[]) => {
    return{
        type: SET_EVALUATION_EVALUATEDS,
        payload: evaluateds
    }
}