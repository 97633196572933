import React from 'react';
import TaskItem from '../EditarTarea/TaskItem';
// import '../view_DetalleCategoria.scss'
import { ScrollView } from 'devextreme-react';
import { Component } from 'react';

interface IProps {
  data:any,
  deleteTask: any
}

interface IState {
  taskItems: any
}
class TasksItems extends Component<IProps, IState> {
  constructor(props: IProps) {
      super(props);
      this.state = {
        taskItems: null
      }
  }

  componentDidMount() {
    this.setState({ taskItems: <TaskItem prop={this.props.data} deleteTask={this.props.deleteTask} /> })
  }
  
  render() {
    return (<div>
      <ScrollView> <div className="tabpanel-item">{this.state.taskItems}</div> </ScrollView>
    </div>
    )
  }
}
export default TasksItems;