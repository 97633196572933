import defaultUser from '../utils/default-user';
import * as Fnctn  from '../contexts/GenericFunctions'

export async function signIn(email: string, password: string) {
  try {
    let api: string = Fnctn.GetAPI();
    let params: {} = {
      "user": email,
      "password": password
    }

    let response = await fetch(`${api}/token/generate`,
      {
        method: 'POST', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      });

    let token = await response.json()

    params = {
      "user": email,
      "password": password
    }

    response = await fetch(`${api}/login/access`,
      {
        method: 'POST'
        , headers: {
          'Accept': 'application/json'
          , 'Content-Type': 'application/json'
          , 'Authorization': `Bearer ${token.data.token}`
        }
        , body: JSON.stringify(params),

      });

    let loginResult = await response.json();
    loginResult.data[0].token = token.data.token
    document.cookie = "dtUsuario=" +  JSON.stringify(loginResult.data[0]) + ";path=/";
    

    defaultUser.email = loginResult.data[0].name_employee;
    defaultUser.avatarUrl = "../../public/logo_territoriohalcon.png";
    defaultUser.valido = loginResult.valid;
    return {
      isOk: loginResult.valid,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication faile"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
  
}