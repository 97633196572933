import { useState, useEffect } from 'react';
import DropDownButton from 'devextreme-react/drop-down-button';
import '../../LibretaTareas/LibretaTareas.scss'
import { Tooltip } from 'devextreme-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as EVActions from '../../../reduxStore/Evaluations/EV_actions';
import * as Fnctn from '../../../contexts/GenericFunctions';
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader';

interface IProps {
    prop: any,
    deleteEvaluation: (id: string) => void
    updateStatus: () => void
}

function EvaluationItem({ prop, deleteEvaluation, updateStatus }: IProps) {
    const [ItemsMenu, setItemsMenu] = useState<any>([
        { id: 1, name: 'Editar evaluación', icon: 'edit' },
        { id: 2, name: 'Eliminar evaluación', icon: 'close' }
    ]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const nav = async (navigate: any, dispatch: any, objEvaluation: any) => {
        halcoloaderActive();
        if (Fnctn.NullUndefinedEmpty(objEvaluation._id)) objEvaluation._id = null;
        dispatch(EVActions.set_evaluation_id(prop._id));
        dispatch(EVActions.set_evaluation_title(prop.name));
        dispatch(EVActions.set_evaluation_description(prop.description));
        dispatch(EVActions.set_evaluation_evaluators(prop.evaluators));
        dispatch(EVActions.set_evaluation_evaluateds(prop.evaluateds));
        dispatch(EVActions.set_evaluation_form_id(prop.form_id));
        dispatch(EVActions.set_evaluation_img_name(prop.img_name));
        dispatch(EVActions.set_evaluation_img_path(prop.img_path));
        dispatch(EVActions.set_evaluation_order(prop.order));
        dispatch(EVActions.set_evaluation_deploye(prop.deployed));
        await navigate('/EditEvaluation');
        halcoloaderRemove();
    }

    const onItemClick = async (opcion: number, id_evaluation: string) => {
        switch (opcion) {
            case 1:
                nav(navigate, dispatch, prop);
                break
            case 2:
                DeleteEvaluation(id_evaluation);
                break
            case 3:
                DeployeEvaluation(id_evaluation, true);
                break
            case 4:
                DeployeEvaluation(id_evaluation, false);
                break
        }
    };

    const OpcionesRender = (e: any) => {
        const icon = `dx-icon dx-icon-${e.icon}`;
        return (
            <div style={{ width: "0rem", height: "0rem" }}>
                <i className={icon} style={{ fontSize: "20px" }}></i>
                <label style={{ margin: "auto auto auto 1rem" }}>{e.name}</label>
            </div>
        );
    }

    const DeleteEvaluation = async (id_evaluation: string) => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let Objt = { "active": false };
        await fetch(`${api}/rating/evaluations_delete/${id_evaluation}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        deleteEvaluation(id_evaluation);
        halcoloaderRemove();
    }

    const CuestionarioAsociado = () => {
        let icon: any = null;

        if (prop.deployed) {
            let classString = `dx-icon dx-icon-check IconEvaluacion`;
            let id = "IdEvaluation_" + prop._id;
            icon = <div>
                <i className={classString} id={id}></i>
                <Tooltip
                    target={`#${id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={false}
                >
                    <label>Evaluación desplegada</label>
                </Tooltip>
            </div>
        }
        return (icon);
    }

    const EditOpiones = () => {
        if (!prop.deployed) {
            setItemsMenu((prevstatus: any) => [...prevstatus, { id: 3, name: 'Desplegar Evaluación', icon: 'pin' }]);
        }
        else {
            setItemsMenu((items: any) => [...items, { id: 4, name: 'Retirar despliegue', icon: 'unpin' }])
        }
    }

    const DeployeEvaluation = async (id_evaluation: string, status: boolean) => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        let Objt = { "deployed": status };
        await fetch(`${api}/rating/evaluations_delete/${id_evaluation}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            },
            body: JSON.stringify(Objt)
        });
        halcoloaderRemove();
        updateStatus();
    }

    return (
        <div>
            <div className='listCategoriesContent'>
                <img src={prop.img_path} alt='Imagen de evaluación' className='ImgCategories' />
                <label className='lbTitleCategories'>{prop.name}</label>
                {CuestionarioAsociado()}
                <DropDownButton
                    className='menuEvaluations'
                    splitButton={false}
                    useSelectMode={false}
                    icon="overflow"
                    showArrowIcon={false}
                    items={ItemsMenu}
                    displayExpr="name"
                    keyExpr="_id"
                    dropDownOptions={{ width: "15rem" }}
                    onItemClick={(e) => onItemClick(e.itemData.id, prop._id)}
                    itemRender={(e) => OpcionesRender(e)}
                    onInitialized={() => EditOpiones()}
                />
            </div>
        </div>
    );
}
export default EvaluationItem;