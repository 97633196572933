import React, { useState, useRef, useCallback } from 'react';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import './LoginForm.scss';
import * as Fnctn from '../../contexts/GenericFunctions'

export default function LoginForm() {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '' });

  const onSubmit = useCallback(async (e: any) => {
    if(!Fnctn.NullUndefinedEmpty(e.preventDefault)){
      e.preventDefault();
    }
    
    const { email, password } = formData.current;
    setLoading(true);

    const result = await signIn(email, password);
    if (!result.isOk) {
      setLoading(false);
      notify("El usuario y/o contraseña es incorrecto", 'error', 2000);
    }
  }, [signIn]);

  // const onCreateAccountClick = useCallback(() => {
  //   navigate('/create-account');
  // }, [navigate]);

  return (
    <div>
      <form className={'login-form'} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={'email'}
            editorType={'dxTextBox'}
            editorOptions={UserOptions}
          >
            <RequiredRule message="Debe ingresar un usuario" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={'password'}
            editorType={'dxTextBox'}
            editorOptions={passwordOptions}
          >
            <RequiredRule message="Debe ingresar una contraseña" />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
              onClick={onSubmit}
            >
              <span className="dx-button-text">
                {
                  loading
                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                    : 'Iniciar sesión'
                }
              </span>
            </ButtonOptions>
          </ButtonItem>
          {/* <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            text={'Create an account'}
            width={'100%'}
            onClick={onCreateAccountClick}
          />
        </ButtonItem> */}
        </Form>
      </form>
    </div>
  );
}

const UserOptions = { stylingMode: 'filled', placeholder: 'Usuario', mode: 'text' };
const passwordOptions = { stylingMode: 'filled', placeholder: 'Contraseña', mode: 'password' };
