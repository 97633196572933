import React, { useState, useCallback } from 'react';
import 'devextreme/data/odata/store';
import { Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import Popup from 'devextreme-react/popup';
import { useNavigate } from 'react-router';
import NuevaCategoria from '../NuevaCategoría/NuevaCategoria'
import './LibretaTareas.scss'
import AccordionCategorias from '../../components/LibretaTareas_Categorias/LibretaTareas_Categorias';
import EditarCategorias from '../NuevaCategoría/EditarCategoria'
import { useDispatch } from 'react-redux';
import * as lbActions from '../../reduxStore/LibretaTareas/LB_actions';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';

export default function Task() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [popupVisible, setPopupVisible] = useState(false);
    const [visibleCategorias, setvisibleCategorias] = useState(false);
    const [listCategories, setListCategories] = useState(<AccordionCategorias></AccordionCategorias>);
    const handlePopupHidden = async () => {
        setPopupVisible(false);
        setvisibleCategorias(false);
        await setListCategories(<div></div>);
        await setListCategories(<AccordionCategorias></AccordionCategorias>);
    }

    const renderPopup = useCallback(() => (
        <div style={{ margin: "40px" }}>
        <NuevaCategoria handlePopupHidden={handlePopupHidden}></NuevaCategoria>
    </div>), [NuevaCategoria]);

    const NuevaTarea = async () => {
        try {
            halcoloaderActive();
            dispatch(lbActions.set_tarea_id("-1"));
            dispatch(lbActions.set_tarea_titulo(""));
            dispatch(lbActions.set_tarea_img(""));
            dispatch(lbActions.set_tarea_imgname(""));
            dispatch(lbActions.set_tarea_contenido(""));
            dispatch(lbActions.set_tarea_vencimiento(false));
            dispatch(lbActions.set_tarea_cantidad(0));
            dispatch(lbActions.set_tarea_tiempo(""));
            dispatch(lbActions.set_tarea_secuencia(""));
            dispatch(lbActions.set_tarea_categoria(""));
            dispatch(lbActions.set_tarea_notificacion(false));
            dispatch(lbActions.set_tarea_autoasignacion(false));
            dispatch(lbActions.set_tarea_tipoasignacion(false));
            dispatch(lbActions.set_tarea_segmentacion([]));
            dispatch(lbActions.set_tarea_segmentacionType(false));
            dispatch(lbActions.set_tarea_from(""));
            dispatch(lbActions.set_tarea_to(""));
            dispatch(lbActions.set_tarea_evaluation_id(null));
            dispatch(lbActions.set_tarea_attachments([]));
            dispatch(lbActions.set_tarea_order(10000));
            await navigate('/tasks');
            
            halcoloaderRemove();
        }
        catch (error) {
            notify(error, 'error');
        }
    };

    const Categorias = async () => {
        try {
            setvisibleCategorias(true);
        }
        catch (error) {
            notify(error, 'error');
        }
    };

    const CreateCategoria = () => {
        try {
            setPopupVisible(true);
        }
        catch (error) {
            notify(error, 'error');
        }
    };

    const renderPopupCategorias = () => {
        return <EditarCategorias></EditarCategorias>
    }

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'} style={{ margin: "30px 0px 30px 190px", width: "80%" }}>
                <div style={{ display: "flex" }}>
                    <Button
                        text='Nueva Tarea'
                        onClick={NuevaTarea}
                        className='btnNuevaTarea'
                    ></Button>
                    <Button
                        text='Nueva categoría'
                        onClick={CreateCategoria}
                        className='btnNuevaCategoria'
                    ></Button>
                    <Button
                        text='Categorías'
                        onClick={Categorias}
                        className='btnCategorias'
                    ></Button>
                </div>
                {listCategories}
            </div>
            <Popup
                width={"70rem"}
                height={"23rem"}
                className='winNuevaCategoría'
                showTitle={false}
                dragEnabled={false}
                hideOnOutsideClick={true}
                visible={popupVisible}
                onHiding={handlePopupHidden}
                contentRender={renderPopup}
                showCloseButton={false}
                resizeEnabled={true}
            />
            <Popup
                width={"80rem"}
                height={"53rem"}
                className='winEditarPregunta'
                showTitle={false}
                dragEnabled={false}
                hideOnOutsideClick={true}
                visible={visibleCategorias}
                onHiding={() => handlePopupHidden()}
                contentRender={() => renderPopupCategorias()}
                showCloseButton={false}
                resizeEnabled={false}
            />
        </React.Fragment>
    )
}