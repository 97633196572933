import React from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { ObtenerTareasCategoria } from '../../../api/DataOnBoarding';
import TabPanelItem from './Componentes_DetalleCategoria/TabPanelItem';
import { Component } from 'react';
import './view_DetalleCategoria.scss'
import { connect } from 'react-redux';
import { RootState } from '../../../reduxStore/reducers';
import { Link } from 'react-router-dom';
import { Button, LoadIndicator } from 'devextreme-react';
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader';

interface IProps{
    IdCategoriaSeleccionada: string
    ImgCategoriaSeleccionada: string
    NombreCategoriaSeleccionada: string
}

interface IState {
    tabsPosition: any
    , stylingMode: any
    , iconPosition: any
    , ListaTareas: any
    , Loading: any
}

class view_DetalleCategoria extends Component<IProps, IState>{
    constructor(props: IProps){
        super(props);
        this.state = {
            tabsPosition: 'top'
            , stylingMode: 'primary'
            , iconPosition: 'top'
            , ListaTareas: []
            , Loading: false
        }
    }

    componentDidMount(): void {
        this.ObtenerListaTareas()
    }

    ObtenerListaTareas = async () => {
        // this.setState({ Loading: true })
        halcoloaderActive()

        let result = await ObtenerTareasCategoria(this.props.IdCategoriaSeleccionada)
        this.setState({ ListaTareas: result })
        
        halcoloaderRemove()
        this.setState({ Loading: false })
    }
    
    render(){
        return (
            <div style={{ flex: 1 }}>
                <div className='BottonAtras' >
                    <Link to={'/OverBoard'}>
                        <Button className='btnAtras' icon="back" text="Atras"></Button>
                    </Link>
                </div>

                <div className='TituloCategoria'>
                    <div className='divImagenCategoria'>
                        <img className="imagenTitulo" src={`${this.props.ImgCategoriaSeleccionada}`} alt='Img' />
                    </div>
                    <div className='divTituloCategoria'>
                        <h2 style={{ textAlign: 'left'}}>{this.props.NombreCategoriaSeleccionada}</h2>
                    </div>
                </div>

                <div className="tabpanel">
                    <div className="widget-container">
                        {
                            this.state.Loading ? 
                                <LoadIndicator height={60} width={60} />
                            :
                                <TabPanel
                                    className="dx-theme-background-color TabPanelTareas"
                                    showNavButtons={true}
                                    animationEnabled={true}
                                    swipeEnabled={true}
                                    dataSource={this.state.ListaTareas}
                                    tabsPosition='top'
                                    stylingMode='primary'
                                    iconPosition='top'
                                    itemComponent={TabPanelItem}
                                />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        IdCategoriaSeleccionada: state.OverBoard.IdCategoriaSeleccionada,
        ImgCategoriaSeleccionada: state.OverBoard.ImgCategoriaSeleccionada,
        NombreCategoriaSeleccionada: state.OverBoard.NombreCategoriaSeleccionada
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(view_DetalleCategoria)
