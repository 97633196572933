
import React, { useState, useEffect } from 'react';
import { Button, List } from 'devextreme-react';
import { halcoloaderActive, halcoloaderRemove } from '../../contexts/HalcoLoader';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ItemDragging } from 'devextreme-react/list';
import * as Fnctn from '../../contexts/GenericFunctions'
import * as NEVActions from '../../reduxStore/Evaluations/NewEvaluations/NEV_actions';
import EvaluationItem from '../Evaluations/Evaluations_items/EvaluationItem';
import notify from 'devextreme/ui/notify';
import './Evaluations.scss'

export default function Evaluations() {
    const [evaluations, setEvaluations] = useState<any[]>([]);

    useEffect(() => {
        GetEvaluations();
    }, []);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const nav = async (dispatch: any) => {
        halcoloaderActive();
        dispatch(NEVActions.set_evaluation_id(""));
        dispatch(NEVActions.set_evaluation_title(""));
        dispatch(NEVActions.set_evaluation_description(""));
        dispatch(NEVActions.set_evaluation_evaluators([]));
        dispatch(NEVActions.set_evaluation_evaluateds([]));
        dispatch(NEVActions.set_evaluation_img(""));
        navigate('/NewEvaluation');
        halcoloaderRemove();
    }

    const GetEvaluations = async () => {
        halcoloaderActive();
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        const dtresult = await fetch(`${api}/rating/evaluations`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        const dtEvalautions = await dtresult.json();
        const sortedItems = await [...dtEvalautions.data].sort((a, b) => a.order - b.order);
        setEvaluations(sortedItems);
        halcoloaderRemove();
    }

    const deleteEvaluation = async (id_evaluation: string) => {
        halcoloaderActive();
        let evaluations_updateds: any[] = evaluations.filter((item: any) => item._id !== id_evaluation);
        const sortedItems = await [...evaluations_updateds].sort((a, b) => a.order - b.order);
        setEvaluations(sortedItems);
        halcoloaderRemove();
    }

    const onDragStart = (e: any) => {
        e.itemData = evaluations[e.fromIndex]
    }

    const onAdd = (e: any) => {
        try {
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();

            setEvaluations((prevEvaluations: any) => {
                const updatedEvaluations = [...prevEvaluations.slice(0, e.toIndex), e.itemData, ...prevEvaluations.slice(e.toIndex)];

                for (let i: number = 0; i < updatedEvaluations.length; i++) {
                    updatedEvaluations[i].order = i;
                }

                updatedEvaluations.forEach(async (element: any) => {
                    let Objt = { "order": element.order };

                    let dtEvaluations: any = await fetch(`${api}/rating/evaluations/${element._id}`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.token}`
                        },
                        body: JSON.stringify(Objt)
                    });

                    dtEvaluations = await dtEvaluations.json();
                    if (!dtEvaluations.valid) throw (dtEvaluations.message);
                });

                return updatedEvaluations.map((item, index) => ({ ...item, order: index }));
            });
        }
        catch (error) {
            halcoloaderRemove();
            notify(error, 'error');
        }
    };

    const onRemove = async (e: any) => {
        const evaluationsResult = evaluations;
        const updatedEvaluations = [...evaluationsResult.slice(0, e.fromIndex), ...evaluationsResult.slice(e.fromIndex + 1)];
        await setEvaluations(updatedEvaluations);
    };

    const onReorder = async (e: any) => {
        await onRemove(e);
        await onAdd(e);
    };

    const NuevaEvaluacion = () => nav(dispatch);

    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings ContenedorEvaluations'}>
                <div style={{ display: "flex" }}>
                    <Button
                        text='Nueva Evaluación'
                        onClick={() => NuevaEvaluacion()}
                        className='btnNuevaEvaluacion'
                    ></Button>
                </div>
                <div>
                    <div className="tabpanel-item">{
                        <List
                            dataSource={evaluations}
                            height={400}
                            selectionMode={"none"}
                            displayExpr={"name"}
                            keyExpr={"_id"}
                            itemComponent={(prop: any) => <div><EvaluationItem prop={prop.data} deleteEvaluation={deleteEvaluation} updateStatus={GetEvaluations} /></div>}
                        >
                            <ItemDragging
                                allowReordering={true}
                                onDragStart={(e) => onDragStart(e)}
                                onAdd={(e) => onAdd(e)}
                                onRemove={(e) => onRemove(e)}
                                onReorder={(e) => onReorder(e)}
                            >
                            </ItemDragging>
                        </List>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}