import { Button, LoadIndicator, Popup, ScrollView } from "devextreme-react";
import React from "react";
import { Component } from "react";
import './Home_OverView.scss'
import { ObtenerListaCategorias } from "../../api/DataOnBoarding";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import * as OB_actions from '../../reduxStore/OverBoard/OB_actions'
import { halcoloaderActive, halcoloaderRemove } from "../../contexts/HalcoLoader";

interface IProps {
    set_Id_CategoriaSeleccionada?: any
    set_Img_CategoriaSeleccionada?: any
    set_Nombre_CategoriaSeleccionada?: any
}

interface IState {
    ListaCategorias?: Array<any>
    Nav?: boolean
    Show_category_message?: boolean
    Loading?: boolean
}

class OV_Categorias extends Component<IProps,IState>{
    constructor(props: IProps){
        super(props)
        this.state = {
            ListaCategorias: [],
            Nav: false,
            Show_category_message: false,
            Loading: false
        }
    }

    componentDidMount(){
    halcoloaderActive()

        this.ObtenerListaCategorias()

    // halcoloaderRemove()
    }

    ObtenerListaCategorias = async () => {
        // this.setState({ Loading: true })

        halcoloaderActive()

        let result = await ObtenerListaCategorias()

        let Cat_ordenados = result.data.sort((a: any,b: any) => {
            if (a.order < b.order)
                return -1
            
            if (a.order > b.order)
                return 1
            
            return 0
        })

        this.setState({ ListaCategorias: Cat_ordenados })
        halcoloaderRemove()
    }
    
    SeleccionarCategoria = (id: number, img: string, name: string, order: number) => {
        if (order === 0){
            this.props.set_Id_CategoriaSeleccionada(id.toString())
            this.props.set_Img_CategoriaSeleccionada(img.toString())
            this.props.set_Nombre_CategoriaSeleccionada(name.toString())
            
            this.setState({ Nav: true })
        }else{
            let CatAnterior = this.state.ListaCategorias?.filter((c:any) => c.order === order - 1)

            let TareasPendientes = CatAnterior![0].count_started + CatAnterior![0].count_not_started;

            if (TareasPendientes === 0){
                this.props.set_Id_CategoriaSeleccionada(id.toString())
                this.props.set_Img_CategoriaSeleccionada(img.toString())
                this.props.set_Nombre_CategoriaSeleccionada(name.toString())
                
                this.setState({ Nav: true })
            }else{
                this.setState({ Show_category_message: true })
            }
        }
    }

    SaberCategoriaActiva = (DataCategoria: any) => {
        let pendientes = DataCategoria['count_started'] + DataCategoria["count_not_started"]
        if (pendientes === 0) {
            return false
        }else if (DataCategoria['order'] === 0) {
            return true
        }else{
            let CatAnterior = this.state.ListaCategorias?.filter((c:any) => c.order === DataCategoria['order'] - 1)
            let pendientesAnterior = CatAnterior![0].count_started + CatAnterior![0].count_not_started

            if (pendientesAnterior === 0)
                return true
            else
                return false
        }
    }

    ListarCategorias = () => {
        return (
            this.state.ListaCategorias?.map((rowData, index) => (
                index !== 0 ?
                <div key={index} className="" style={{ display:'flex' , flexDirection:"column" }}>
                    <div key={index} className={rowData['activo'] ? 'barraSeparadoraActivo' : 'barraSeparadora'} style={{ width: 1, alignSelf: 'center' }}>
                    </div>
                    <Button onClick={() => { this.SeleccionarCategoria(rowData["_id"], rowData["img_path"], rowData["name"], rowData["order"] ) }} className={rowData["active"] ? "botonCategoriaActivo" : "botonCategoria"} style={{ alignSelf: 'center' }} >
                        <div className='dx-field' style={{display:'flex', width: '20%', height: '100%', alignSelf:'center', flexDirection: 'column', justifyContent: 'center'}}>
                                {
                                    this.SaberCategoriaActiva(rowData) ?
                                        <div className="indicadorCategoriaActual" style={{ alignSelf:'center' }}>TU</div>
                                    :
                                        <div className="indicadorCategoriaActual" style={{ alignSelf:'center', display: 'none' }}>TU</div>
                                }
                                <div style={{display:'flex', alignSelf: 'center', width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                                    <img className="imgCategoria" alt="Img" src={`${rowData["img_path"]}`}
                                        style={{ width: 150, height: 150, alignSelf: 'center' }}
                                    />
                                </div>
                                <div className="textoNombreCategoria" style={{ alignSelf: 'center', justifyContent:'center' }}>
                                    {rowData["name"]}
                                </div>
                                <div className="textoTareasPendiente" style={{ alignSelf: 'center' }}>
                                    { `${rowData['count_not_started'] + rowData['count_started']} tareas pendientes`}
                                </div>
                        </div>
                    </Button>
                </div>
                :
                <div key={index} className="" style={{ display:'flex' , flexDirection:"column" }}>
                    <Button onClick={() => { this.SeleccionarCategoria(rowData["_id"], rowData["img_path"], rowData["name"], rowData["order"] ) }} className={rowData["active"] ? "botonCategoriaActivo" : "botonCategoria"} style={{ alignSelf: 'center' }} >
                        <div className='dx-field' style={{display:'flex', width: '20%', height: '100%', alignSelf:'center', flexDirection: 'column', justifyContent: 'center'}}>
                                {
                                    this.SaberCategoriaActiva(rowData) ?
                                        <div className="indicadorCategoriaActual" style={{ alignSelf:'center' }}>TU</div>
                                    :
                                        <div className="indicadorCategoriaActual" style={{ alignSelf:'center', display: 'none' }}>TU</div>
                                }
                                <div style={{display:'flex', alignSelf: 'center', width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                                    <img className="imgCategoria" alt="Img" src={`${rowData["img_path"]}`}
                                        style={{ width: 150, height: 150, alignSelf: 'center' }}
                                    />
                                </div>
                                <div className="textoNombreCategoria" style={{ alignSelf: 'center', justifyContent:'center' }}>
                                    {rowData["name"]}
                                </div>
                                <div className="textoTareasPendiente" style={{ alignSelf: 'center' }}>
                                    { `${rowData['count_not_started'] + rowData['count_started']} tareas pendientes`}
                                </div>
                        </div>
                    </Button>
                </div>
            ))
        )
    }


    render(){
        const { Show_category_message } = this.state

        return (
            <div>
                <Popup
                    visible={Show_category_message}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    onHiding={() => this.setState({ Show_category_message: false })}
                    showCloseButton={false}
                    showTitle={false}
                    width={'auto'}
                    height={'auto'}
                >
                    <div style={{ justifyContent: 'center' }} >
                        <label style={{ fontWeight: 'bold', fontSize: 18, width: '100%' }}>No se puede ver la categoria</label>
                        <p style={{ fontSize: 15, marginTop: 20, marginBottom: 20 }}>Para ver la categoría necesitas tener completadas todas las tareas de la anterior</p>
                        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            <Button className='botonAceptar' onClick={() => this.setState({ Show_category_message: false }) }><label style={{ fontWeight: 'bold', fontSize: 13 }}>Aceptar</label></Button>
                        </div>
                    </div>
                </Popup>
                { this.state.Nav && <Navigate to='/DetalleCategoria'/> }
                <ScrollView
                    scrollByThumb={true}
                >
                    <div className="scrollPrincipal">
                        {
                            this.state.Loading
                            ?
                                <div style={{ flex: 1 ,alignSelf: 'center', justifyContent: 'center' }}>
                                    <LoadIndicator id="large-indicator" height={60} width={60} />
                                </div>
                            :
                                this.ListarCategorias()
                        }
                    </div>
                </ScrollView>
            </div>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        set_Id_CategoriaSeleccionada: (id: string) => dispatch(OB_actions.set_categoria_id(id)),
        set_Img_CategoriaSeleccionada: (img: string) => dispatch(OB_actions.set_categoria_img(img)),
        set_Nombre_CategoriaSeleccionada: (name: string) => dispatch(OB_actions.set_categoria_nombre(name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OV_Categorias)

