import { initializeApp, FirebaseApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from 'firebase/storage'
import {  } from 'firebase/storage'; 
import { GenerarUUID } from './GenericFunctions';
import { halcoloaderActive, halcoloaderRemove } from './HalcoLoader';



const firebaseConfig = {
    apiKey: "AIzaSyB3H2E4e4e8lLUyKfSfGCEbCap3jgR5Cs4",
    authDomain: "halconet-27dd9.firebaseapp.com",
    databaseURL: "https://halconet-27dd9.firebaseio.com",
    projectId: "halconet-27dd9",
    storageBucket: "halconet-27dd9.appspot.com",
    messagingSenderId: "566275200622",
    appId: "1:566275200622:web:e1a844f6d033f3239eb87f",
    measurementId: "G-FCGFBWD737"
};

export const enumPath = {
    ICONOS_CATEGORIAS: 'HalcoHumand/ICONOS_CATEGORIAS',
    ICONOS_TAREAS: '/HalcoHumand/ICONOS_TAREAS',
    ARCHIVOS_EVALUACIONES: "/HalcoHumand/ARCHIVOS_EVALUACIONES",
    ARCHIVOS_TAREAS: "/HalcoHumand/ARCHIVOS_TAREAS",
    ARCHIVOS_EVALUACIONES_TH: "/HalcoHumand/ARCHIVOS_EVALUACIONES_TH"
}
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export async function uploadFiles(files: any, path: any) {
    try {
        const resultados = await Promise.all(Array.from(files).map(
            (file) => { return uploadFile(file, path) }
        ))

        return resultados
    } catch (error) {
        console.log(error)
    }
}

export async function uploadFile(file: any, path: any){
    let objFile = {
        Status: 'En proceso de carga',
        PathFireBase: '',
        NameFirebase: '',
        NameFile: '',
        Description: '',
        Success: false
    }

    try {
        const storage = getStorage()
        const fileName = `${GenerarUUID()}_HF__${file.name}`
        const pathFirebase = `${path}/${fileName}`
        const FileRef = storageRef(storage, pathFirebase)

        await uploadBytes(FileRef, file)
        .then(async(snapshot) => {
            await getDownloadURL(snapshot.ref)
            .then((url) => {
                objFile.Status = 'Archivo cargado con exito'
                objFile.PathFireBase = url
                objFile.Success = true
                objFile.NameFirebase = fileName
                objFile.NameFile = file.name

            })
            .catch((error) => {
                console.log("error getURL",error)
            })
        })
        .catch((error) => {
            console.log("error upload", error)
            objFile.Status = 'Error: ' + error
            objFile.Success = false
            
        })
        return objFile
    } catch (error) {
        objFile.Status = 'Error: ' + error
        objFile.Success = false

        return objFile
    }
}

export async function DescargarArchivo(FileName: any, path: any){
    const storage = getStorage()
    const pathFirebase = `${path}/${FileName}`
    const FileRef = storageRef(storage, pathFirebase)

    getDownloadURL(FileRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob'
            xhr.onload = (event) => {
                const blob = xhr.response;

                const link = document.createElement("a")
                link.href = URL.createObjectURL(blob);
                let split = pathFirebase.split('/')
                link.download = split.pop()!
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
            xhr.open('GET', url)
            xhr.send()


        })
}

export async function DeleteFile(FileName: any, path: any){
    let objResult = {
        Status: 'En proceso de carga',
        PathFireBase: '',
        NameFirebase: '',
        NameFile: '',
        Description: '',
        Success: false
    }
    
    try {
        const storage = getStorage()
        const pathFirebase = `${path}/${FileName}`
        const FileRef = storageRef(storage, pathFirebase)

        await deleteObject(FileRef)       

        objResult.Status = 'Archívo eliminado correctamente'
        objResult.Success = true
        return objResult
    } catch (error) {
        objResult.Status = 'Error: ' + error
        objResult.Success = false
        return objResult
    }
}

export async function viewFile(path: any, filename: any) {
    halcoloaderActive();
    const storage = getStorage(app)
    const fileRef = storageRef(storage, `${path}/${filename}`);
    const url = await getDownloadURL(fileRef);
    openFileFirebase(url);
    halcoloaderRemove();
};

const openFileFirebase = (url: string) => {
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};